import Header from '../../header/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Sing_in_up.scss';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../App';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Login from '../../Components/google';

const SignupSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});



export const Signup = () => {

  const user = useContext(ThemeContext);
  const navigate = useNavigate();
  const [error, setError] = useState();


  
  const randomString = 'guest-'+ Math.random().toString(36).substring(7);
  const randomNum =  Math.floor(Math.random() * 10000);
  const randomEmail = randomString + randomNum + '@gmail.com';
  const randomName = randomString + randomNum;
  function guest() {
    axios
      .post('/api/v1/users/register', {
        name: randomName,
        email: randomEmail,
        phone: '12345'+randomNum,
        password: '123456',
      })
      .then(
        response => {
          console.log(response);
          user.setUser(response.data);
        },
        error => {
          console.log(error);
          if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error);
          } else {
            setError('An unknown error occurred.');
          }
        }
      );
  }

  return (
    <div>
      <h1>
        Signup
      </h1>
      {/* <Login /> */}
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          axios
            .post('/api/v1/users/register', values)
            .then(
              response => {
                console.log(response);
                user.setUser(response.data);
              },
              error => {
                console.log(error);
                if (error.response && error.response.data && error.response.data.error) {
                  setError(error.response.data.error);
                } else {
                  setError("An unknown error occurred.");
                }
              }
            );

        }}
      >
        {({ errors, touched }) => (
          <Form>
            {error ? <div className='error' >{error}</div> : null}
            <Field name="name" placeholder="Enter your name" />
            {errors.name && touched.name ? (
              <div>{errors.name}</div>
            ) : null}
            <Field name="email" type="email" placeholder="Enter your email" />
            {errors.email && touched.email ? (
              <div>{errors.email}</div>
            ) : null}
            <Field name="phone" placeholder="Enter your phone" />
            {errors.phone && touched.phone ? (<div>{errors.phone}</div>) : null}
            <Field
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            {errors.password && touched.password ? (
              <div>{errors.password}</div>
            ) : null}
            <button type="submit">Signup</button>
            <button type="button" onClick={guest}>تسجيل كضيف</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};



const LoginSchema = Yup.object().shape({
    name: Yup.string() , 
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });

   const Login_IN = () => {
  
    const user = useContext(ThemeContext);
    const navigate = useNavigate();
    const [error, setError] = useState();
  
    if (user.user !=null) {
      navigate('/');
    }
   
  
    return (
    <div>
      <h1>login </h1>
      {/* <Login />  */}
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={values => {
          // same shape as initial values
          
          axios.post('/api/v1/users/login', values)
          .then(
            response => {
              console.log(response);
              user.setUser(response.data);
            },
            error => {
              console.log(error);
              if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
              } else {
                setError("An unknown error occurred.");
              }
            }
          );
        
        }}
      >
        {({ errors, touched }) => (
          <Form>
          {error ? <div className='error' >{error}</div> : null}
            <Field name="email" type="email"  placeholder='Enter your email' />
            {errors.email && touched.email ? <div>{errors.email}</div> : null}
            <Field name="password" type="password"  placeholder='Enter your password' />
            {errors.password && touched.password ? <div>{errors.password}</div> : null}
            <button type="submit">login</button>
          </Form>
        )}
      </Formik>
    </div>
    );
  }


function Sing_in_out() {
    const [isLogin, setIsLogin] = useState(true);
    const [isSignup, setIsSignup] = useState(false);

    return (
        <div>
            <div className="Sing_in_up">
                <h1>سجل لك تتمتع بي خدماتنا بشكل افضل</h1>
                <button onClick={() => { setIsLogin(true); setIsSignup(false) }}>login</button>
                <button onClick={() => { setIsLogin(false); setIsSignup(true) }}>signup</button>
                <h1>
                    {isSignup && <Signup />}
                    {isLogin && <Login_IN />}
                </h1>
            </div>
        </div>
    );
}


export default Sing_in_out;