import Header from '../../../header/Header';
import ReactDOM from "react-dom"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { NavLink, useParams } from 'react-router-dom';


import { useContext } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";

import '../product.scss'
import './EditCategory.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allCategory, addCategory, deleteCategory, editCategory } from '../../../api/Category';
import { ThemeContext } from '../../../App';
import Menu from '../../../Components/menu';


import React, { useState, useEffect } from 'react';


function List() {
  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [category, setCategory] = useState([]);

  const { id } = useParams();
  const [printer, setPrinter] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event, newValues) => {
    setSelectedValues(newValues.map((value) => ({ _id: value })));
  };


  useEffect(() => {
    getAllCat();
    getPrinter();
  }, []);

  const getAllCat = async () => {
    try {
      const response = await allCategory(id);
      setCardUpdate(response.data);
      setCategory(response.data);
      setSelectedValues(response.data.printers);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };

  const getPrinter = async () => {
    try {
      const response = await fetch('/api/v1/printer', { method: 'GET' });
      if (response.ok) {
        const data = await response.json();
        setPrinter(data.data);
      } else {
        console.error(`Error: ${response.status}`);
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };

  const addProduct = async (e) => {
    e.preventDefault();

    // Get form data and printers from state
    const formData = new FormData(e.target);
    const printers = selectedValues.map((p) => p._id);
    console.log(printers);
    // Append printers to form data
    formData.append('printers', JSON.stringify(printers));

    let response;
    try {
      // Call the appropriate API function based on whether we are adding or updating a category
      if (cardUpdate._id) {
        response = await editCategory(cardUpdate._id, formData, Theme.user.accessToken);
      }

      // Check the response status and show a success or error message
      if (response.status === 201 || response.status === 200) {
        toast.success('Successfully');
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };




  return (
    <div className='editCategory'>
      <Menu />
      <div className="listProddct ">
        <ToastContainer />
        <h1>Edit/Catgory </h1>
        <h2>{category.name}</h2>
        <div className='add' ><IoAddCircleOutline size={28} /> add Catgory </div>
        <div className=" ">

          <form onSubmit={addProduct} method="post" encType='multipart/form-data'>

            <div className="form-group">
              <label htmlFor="image">image</label>
              <div id="im">
                <img src={
                  cardUpdate.image ? cardUpdate.image : '/upload.png'
                } alt='upload' />
                <input type="file" name="image" id="image" placeholder='image' onChange={
                  (e) => setCardUpdate({ ...cardUpdate, image: URL.createObjectURL(e.target.files[0]) })
                } />
              </div>
            </div>

            <input type="text" name='_id' value={cardUpdate._id} hidden />
            <div className="form-group">
              <label htmlFor="name">name</label>
              <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
              } />
            </div>
            <div className="form-ygh">

              <Autocomplete
                multiple
                id="printers"
                options={printer}
                getOptionLabel={(option) => `${option.type} ${option.name}`}
                defaultValue={selectedValues.map((p) => p._id)}
                onChange={handleChange}
                filterSelectedOptions
                name="printers"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="printers"
                    placeholder="printers"
                  />
                )}
              />



            </div>

            <Button type="submit" variant="contained"  >{cardUpdate._id ? 'Update' : 'Add'}</Button>


          </form>

        </div>

      </div>
    </div>
  );
}


function Category() {
  return (
    <div>
      <Header />
      <div className="admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Category;