import Header from '../../../header/Header';
import ReactDOM from "react-dom"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'; 
import { NavLink } from 'react-router-dom';


import { useContext } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";

import '../product.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allCategory, addCategory, deleteCategory, editCategory } from '../../../api/Category';
import { ThemeContext } from '../../../App';
import Menu from '../../../Components/menu';


import React, { useState, useEffect } from 'react';


function List() {
  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [category, setCategory] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);
  const [printer, setPrinter] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event, newValues) => {
    setSelectedValues(newValues.map((value) => ({ _id: value })));
  };


  useEffect(() => {
    getAllCat();
    getallCategory();
    getPrinter();
  }, []);

  const deletePro = async (id) => {
    await deleteCategory(id, Theme.user.accessToken);
    getAllCat();
  };

  const getallCategory = async () => {
    try {
      const response = await allCategory();
      setCard(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCat = async () => {
    try {
      const response = await allCategory();
      setCategory(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPrinter = async () => {
    try {
      const response = await fetch('/api/v1/printer', { method: 'GET' });
      if (response.ok) {
        const data = await response.json();
        setPrinter(data.data);
      } else {
        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
    console.log(printer);
  };

  const handleEditClick = (card) => {
    setCardUpdate(card);
    setShow(true);
    selectedValues(card.printers);
  };



  const addProduct = async (e) => {
    e.preventDefault();

    // Get form data and printers from state
    const formData = new FormData(e.target);
    const printers = selectedValues.map((p) => p._id._id);
    console.log(printers);
    // Append printers to form data
    formData.append('printers', JSON.stringify(printers));

    let response;
    try {
      // Call the appropriate API function based on whether we are adding or updating a category
      if (cardUpdate._id) {
        response = await editCategory(cardUpdate._id, formData, Theme.user.accessToken);
      } else {
        response = await addCategory(formData, Theme.user.accessToken);
      }

      // Check the response status and show a success or error message
      if (response.status === 201 || response.status === 200) {
        toast.success('Successfully');
        getAllCat();
        setShow(false);
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };



  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
      <Menu />
      <div className="listProddct">
        <ToastContainer />
        <h1>admin/Catgory </h1>

        <div className='add' onClick={() => { setShow(!show); setCardUpdate([]); getPrinter() }}><IoAddCircleOutline size={28} /> add Catgory </div>
        <div className="form">
          {
            show && ReactDOM.createPortal(<div id="form__add">
              <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

              <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
                <input type="text" name='_id' value={cardUpdate._id} hidden />
                <Button type="submit" variant="contained"  >{cardUpdate._id ? 'Update' : 'Add'}</Button>
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                  } />
                </div>
                <div className="imm">
                  <div id="im">
                    <img src={
                      cardUpdate.image ? cardUpdate.image : '/upload.png'
                    } alt='upload' />
                    <input type="file" name="image" id="image" placeholder='image' onChange={
                      (e) => setCardUpdate({ ...cardUpdate, image: URL.createObjectURL(e.target.files[0]) })
                    } />
                  </div>
                </div>
                <div className="form-ygh">

                  <Autocomplete
                    multiple
                    id="printers"
                    options={printer}
                    getOptionLabel={(option) => `${option.type} ${option.name}`}
                    defaultValue={selectedValues}
                    onChange={handleChange}
                    name="printers"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="printers"
                        placeholder="printers"
                      />
                    )}
                  />



                </div>



              </form>
            </div>, document.getElementById('modal'))
          }

        </div>

        <section className="cards">
          {
            showDel &&
            <div className="del">
              <div className="body">
                <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
                <div className="del__card">
                  <p>Are you sure you want to delete this Category?</p>
                  <h2>{del.name}</h2>
                  <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
                </div>
              </div>
            </div>
          }
          {
            category.map((card) => (
              <div className="card" key={card._id}>
                <span className="card__name">{card.name}</span>
                <img src={card.image} alt="card" className="card__img" />
                <div className="edit">
                  <button onClick={() => handleEditClick(card)}> &#10000; Edit</button>
                  {/* <NavLink to={`/admin/category/edit/${card._id}`}><button> &#10000; Edit</button></NavLink>  */}
                  <button onClick={() => { setDel(card); setShowDel(true) }}> &#9832; Delete</button>
                </div>
              </div>
            ))  // end map

          }
        </section>
      </div>
    </div>
  );
}


function Category() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Category;