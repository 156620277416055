import Header from '../../header/Header';
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import './product.scss'
import './Table.scss'
import { ToastContainer, toast } from 'react-toastify';

import QRCode from "react-qr-code";

import 'react-toastify/dist/ReactToastify.css';
import { allTables, addTables, deleteTables, editTables } from '../../api/Table';
import { ThemeContext } from '../../App';


import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';


function List() {
  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [tables, setTables] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);

  const [t, i18n] = useTranslation();


  useEffect(() => {
    getAllTable();
  }, []);

  const deletePro = async (id) => {
    await deleteTables(id, Theme.user.accessToken);
    getAllTable();
  }

  const getAllTable = async () => {
    let response = await allTables();
    setTables(response.data);
  }

  let customer = (item) => {
    Theme.setCustomer(item);
    console.log(item, 'item');
  }


  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    // let response = await addProd(formData, Theme.user.accessToken);
    // let response = cardUpdate._id ? await editTables(cardUpdate._id, { name: e.target.name.value }, Theme.user.accessToken) : await addTables(data, Theme.user.accessToken); // make send full data to server 
    let response = cardUpdate._id ? await editTables(cardUpdate._id, data, Theme.user.accessToken) : await addTables(data, Theme.user.accessToken); // make send full data to server 
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllTable();
      setShow(false);
    } else {
      toast.error('Something went wrong');
    }

  }
  return (
    <div>
      <div className="form">
        <ToastContainer />

        <h1>admin/tables </h1>
        <button style={{ fontSize: "27px;" }} onClick={() => { setShow(!show); setCardUpdate([]) }}>&#10000; add tables </button>
        {
          show && ReactDOM.createPortal(<div id="form__add">
            <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

            <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
              <input type="text" name='_id' value={cardUpdate._id} hidden />
              <div className="form-group">
                <label htmlFor="name">name</label>
                <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="numberOfSeats">{t('numberOfSeats')}</label>
                <input type="text" name="numberOfSeats" id="numberOfSeats" placeholder='numberOfSeats' value={cardUpdate.numberOfSeats} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, numberOfSeats: e.target.value })
                } />
              </div>

              <Button type="submit" variant="contained" color="primary" >{cardUpdate._id ? t('Update') : t('Add')}</Button>


            </form>
          </div>, document.getElementById('modal'))
        }

      </div>

      <section className="cards">
        {
          showDel &&
          <div className="del">
            <div className="body">
              <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
              <div className="del__card">
                <p>Are you sure you want to delete this Tables?</p>
                <h2>{del.name}</h2>
                <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
              </div>
            </div>
          </div>
        }

        <div className="tables0">
          {
            tables.map((card) => (
              <div className="table" style={
                { width: Math.max(50, card.numberOfSeats * 10 + 50) + "px", maxWidth: "400px" }

              }>
                <div className="table__nam">


                  <NavLink to={'/guest/' + card._id} className="number">
                    T {card.tableNumber}
                  </NavLink>

                  <div className="number">Seat {card.numberOfSeats}</div>
                  <div className="tname">{card.name}</div>
                </div>
                <QRCode size={112} value={(
                  // get url host +http://localhost:3000/table/63ed4f22583766898ea72658
                  window.location.protocol + '//' + window.location.host + '/guest/' +
                  card._id
                ) || 'null'} />
                {/* <div className='btn btn-success' onClick={() => { setCardUpdate(card); setShow(true) }}> <FaRegEdit /></div>
                <div className='btn btn-danger' onClick={() => { setDel(card); setShowDel(true) }}> <FaTrashAlt /> </div> */}
              </div>
            ))
          }
        </div>

      </section>


    </div>
  );
}


function Tables() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Tables;
