import Header from '../../header/Header';
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { FaTrashAlt, FaRegEdit } from "react-icons/fa";
import './product.scss'
import { ToastContainer, toast } from 'react-toastify';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import 'react-toastify/dist/ReactToastify.css';
import { allCustomers, addCustomers, deleteCustomers, editCustomers } from '../../api/Customers';
import { ThemeContext } from '../../App';


import React, { useState, useEffect } from 'react';


function List() {
  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);

  const [t, i18n] = useTranslation();


  useEffect(() => {
    getAllCat();
    getallCustomers();
  }, []);

  const deletePro = async (id) => {
    await deleteCustomers(id, Theme.user.accessToken);
    getAllCat();
  }
  const getallCustomers = async () => {
    let response = await allCustomers();
    setCard(response.data.data);
  }

  const getAllCat = async () => {
    let response = await allCustomers();
    setCustomers(response.data);
  }

  let customer = (item) => {
    Theme.setCustomer(item);
    console.log(item, 'item');
  }


  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    // let response = await addProd(formData, Theme.user.accessToken);
    // let response = cardUpdate._id ? await editCustomers(cardUpdate._id, { name: e.target.name.value }, Theme.user.accessToken) : await addCustomers(data, Theme.user.accessToken); // make send full data to server 
    let response = cardUpdate._id ? await editCustomers(cardUpdate._id, data, Theme.user.accessToken) : await addCustomers(data, Theme.user.accessToken); // make send full data to server 
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllCat();
      setShow(false);
    } else {
      toast.error('Something went wrong');
    }

  }
  return (
    <div>
      <div className="form">
        <ToastContainer />

        <h1>admin/customers </h1>
        <button style={{ fontSize: "27px;" }} onClick={() => { setShow(!show); setCardUpdate([]) }}>&#10000; add customers </button>
        {
          show && ReactDOM.createPortal(<div id="form__add">
            <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

            <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
              <input type="text" name='_id' value={cardUpdate._id} hidden />
              <div className="form-group">
                <label htmlFor="name">name</label>
                <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="phone">{t('phone')}</label>
                <input type="text" name="phone" id="phone" placeholder='phone' value={cardUpdate.phone} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, phone: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="email">{t('email')}</label>
                <input type="text" name="email" id="email" placeholder='email' value={cardUpdate.email} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, email: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="building">{t('building')}</label>
                <input type="text" name="building" id="building" placeholder='building' value={cardUpdate.building} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, building: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="street">{t('street')}</label>
                <input type="text" name="street" id="street" placeholder='street' value={cardUpdate.street} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, street: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="city">{t('city')}</label>
                <input type="text" name="city" id="city" placeholder='city' value={cardUpdate.city} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, city: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="area">{t('area')}</label>
                <input type="text" name="area" id="area" placeholder='area' value={cardUpdate.area} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, area: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="address">{t('address')}</label>
                <input type="text" name="address" id="address" placeholder='address' value={cardUpdate.address} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, address: e.target.value })
                } />
              </div>
              <div className="form-group">
                <label htmlFor="notes">{t('notes')}</label>
                <input type="text" name="notes" id="notes" placeholder='notes' value={cardUpdate.notes} onChange={
                  (e) => setCardUpdate({ ...cardUpdate, notes: e.target.value })
                } />
              </div>

              <button type="submit">{cardUpdate._id ? t('Update') : t('Add')}</button>


            </form>
          </div>, document.getElementById('modal'))
        }

      </div>

      <section className="cards">
        {
          showDel &&
          <div className="del">
            <div className="body">
              <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
              <div className="del__card">
                <p>Are you sure you want to delete this Customers?</p>
                <h2>{del.name}</h2>
                <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
              </div>
            </div>
          </div>
        }
        <table>
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('phone')}</th>
              <th>{t('email')}</th>
              <th>{t('building')}</th>
              <th>{t('street')}</th>
              <th>{t('city')}</th>
              <th>{t('area')}</th>
              <th>{t('address')}</th>
              <th>{t('notes')}</th>
              <th>{t('edit')}</th>
              <th>{t('delete')}</th>
            </tr>
          </thead>
          <tbody>
            {
              customers.map((card) => (
                <tr key={card._id} onClick={() => { customer(card) }}>
                  <td>{card.name}</td>
                  <td>{card.phone}</td>
                  <td>{card.email}</td>
                  <td>{card.building}</td>
                  <td>{card.street}</td>
                  <td>{card.city}</td>
                  <td>{card.area}</td>
                  <td>{card.address}</td>
                  <td>{card.notes}</td>
                  <td><div className='btn btn-success' onClick={() => { setCardUpdate(card); setShow(true) }}> <FaRegEdit /></div></td>
                  <td><div className='btn btn-danger' onClick={() => { setDel(card); setShowDel(true) }}> <FaTrashAlt /> </div></td>
                </tr>
              ))
            }
          </tbody>
        </table>



      </section>


    </div>
  );
}


function Customers() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Customers;
