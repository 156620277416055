import Header from '../../header/Header';
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { IoAddCircleOutline } from "react-icons/io5";

import './product.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allCategory } from '../../api/Category';
import { allSubcatogery, addSubcatogery, deleteSubcatogery, editSubcatogery } from '../../api/SubCategory';
import { allProduct, addProd, updateProd, deleteProduct } from '../../api/Product';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';
import ImportCSV from '../../Components/import';


import React, { useState, useEffect } from 'react';


function List() {


  // const productSchema = new mongoose.Schema(
  //   {
  //       category: { type: mongoose.Schema.Types.ObjectId,
  //           ref: 'Category',
  //       },
  //       subcategory: { type: mongoose.Schema.Types.ObjectId,
  //           ref: 'SubCategory',
  //       },
  //       modifier: [
  //           {
  //               name: { type: String },
  //               price: { type: Number }
  //           }
  //       ],
  //       name: { type: String , unique: true },
  //       description: { type: String },
  //       image: { type: String, default: "" },
  //       images: [ { type: String } ], 
  //       quantity: { type: Number, default: 0 },
  //       price: { type: Number, default: 0 },
  //       purchase: { type: Number, default: 0 },
  //       discount: { type: Number, default: 0 },
  //       tax: { type: Number, default: 0 },
  //       productCode: { type: String, unique: true
  //       }

  //   },
  //   { timestamps: true }
  // );


  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [category, setCategory] = useState([]);
  const [show, setShow] = useState(false);

  const [SubCategory, setSubCategory] = useState([]);

  const [modifiers, setModifiers] = useState([]);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);




  useEffect(() => {
    getAllCat();
    getAllSubCat();
    getAllProduct();
  }, []);

  const deletePro = async (id) => {
    await deleteProduct(id, Theme.user.accessToken);
    getAllProduct();
  }
  const getAllProduct = async () => {
    let response = await allProduct();
    setCard(response.data.data);
    console.log(response.data.data, 'response');
  }
  console.log('useEffect', card);

  const getAllCat = async () => {
    let response = await allCategory();
    setCategory(response.data);
  }

  const getAllSubCat = async (e) => {
    let response = await allSubcatogery('category/' + e);
    setSubCategory(response.data);
  }

  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // const data = Object.fromEntries(formData.entries());
    // let response = await addProd(formData, Theme.user.accessToken);
    // add modifier to formData 
    formData.append('modifier', JSON.stringify(modifiers));

    let response = cardUpdate._id ? await updateProd(cardUpdate._id, formData, Theme.user.accessToken) : await addProd(formData, Theme.user.accessToken);
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllProduct();
      setShow(false);
      setCardUpdate([]);
    } else {
      toast.error('Something went wrong');
    }

  }



  const handleChange = (event, index, key) => {
    const list = [...modifiers];
    list[index][key] = event.target.value;
    setModifiers(list);
  };

  const handleAddModifier = () => {
    setModifiers([...modifiers, { name: '', price: 0 }]);
  };

  const handleRemoveModifier = (index) => {
    const list = [...modifiers];
    list.splice(index, 1);
    setModifiers(list);
  };




  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }} >
      <Menu />
      <div className="listProddct">

        <ToastContainer />
        <h1>admin/product </h1>
        {/* <ImportCSV /> */}

        <div className='add' onClick={() => setShow(!show)}><IoAddCircleOutline size={28} /> add product</div>

        <div className="form">
          {
            show && ReactDOM.createPortal(<div id="form__add">
              <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

              <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
                <button type="submit">{cardUpdate._id ? 'Update' : 'Add'}</button>
                <h1>{cardUpdate._id ? 'Update Product' : 'Add Product'}</h1>
                <div className="form-group">
                  <label htmlFor="category">category</label>
                  <br />
                  <select name='category' defaultValue={cardUpdate.category?._id} onChange={(e) => { getAllSubCat(e.target.value) }} >
                    <option value=''>select</option>
                    {category.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}

                      </option>
                    ))}

                  </select>

                </div>
                <div className="form-group">
                  <label htmlFor="subCategory">subCategory</label>
                  <br />
                  <select name='subcategory' defaultValue={cardUpdate.subcategory} >
                    <option value=''>select</option>
                    {SubCategory.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}

                      </option>
                    ))}

                  </select>

                </div>
                <input type="text" name='_id' value={cardUpdate._id} hidden />
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                  } />
                </div>
                <div className="form-group">
                  <label htmlFor="description">description</label>
                  <textarea type="text" name="description" id="description" placeholder='description' value={cardUpdate.description} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, description: e.target.value })
                  } />
                </div>
                <div className="imm">
                  <div id="im">
                    <img src={
                      cardUpdate.image ? cardUpdate.image : '/upload.png'
                    } alt='upload' />
                    <input type="file" name="image" id="image" placeholder='image' onChange={
                      (e) => setCardUpdate({ ...cardUpdate, image: URL.createObjectURL(e.target.files[0]) })
                    } />
                  </div>
                </div>
                <div className="num">

                  <div className="form-group">
                    <label htmlFor="price">price</label>
                    <input type="text" name="price" id="price" placeholder='price' value={cardUpdate.price} onChange={
                      (e) => setCardUpdate({ ...cardUpdate, price: e.target.value })
                    } />
                  </div>
                  <div className="form-group">
                    <label htmlFor="priceBeforeDiscount">priceBeforeDiscount</label>
                    <input type="text" name="priceBeforeDiscount" id="priceBeforeDiscount" placeholder='priceBeforeDiscount' value={cardUpdate.priceBeforeDiscount} onChange={
                      (e) => setCardUpdate({ ...cardUpdate, priceBeforeDiscount: e.target.value })
                    } />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="purchase">purchase</label>
                    <input type="text" name="purchase" id="purchase" placeholder='purchase' value={cardUpdate.purchase} onChange={
                      (e) => setCardUpdate({ ...cardUpdate, purchase: e.target.value })
                    } />
                  </div>

                  <div className="form-group" hidden >
                    <label htmlFor="discount">discount</label>
                    <input type="text" name="discount" id="discount" placeholder='discount' value={cardUpdate.discount} onChange={
                      (e) => setCardUpdate({ ...cardUpdate, discount: e.target.value })
                    } />
                  </div>
                  <div className="form-group">
                    <label htmlFor="discount">tax</label>
                    <input type="text" name="tax" id="tax" placeholder='tax' value={cardUpdate.tax} onChange={
                      (e) => setCardUpdate({ ...cardUpdate, tax: e.target.value })
                    } />
                  </div>
                </div>

                <div className='modifier'>
                  <h1>Modifier</h1>

                  {modifiers.map((modifier, index) => (
                    <div key={index} className="modifier__item">
                      <input
                      className='name'
                        type="text"
                        placeholder="Modifier name"
                        value={modifier.name}
                        onChange={(event) => handleChange(event, index, 'name')}
                      />
                      <input
                      className='price'
                        type="text"
                        placeholder="price"
                        value={modifier.price}
                        onChange={(event) => handleChange(event, index, 'price')}
                      />
                      <div onClick={() => handleRemoveModifier(index)}>Remove</div>
                    </div>
                  ))}
                  <div className='addmod' onClick={handleAddModifier}>Add Modifier</div>

                </div>

              </form>


            </div>, document.getElementById('modal'))
          }
        </div>

        <section className="cards">
          {
            showDel &&
            <div className="del">
              <div className="body">
                <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
                <div className="del__card">
                  <p>Are you sure you want to delete this product?</p>
                  <h2>{del.name}</h2>
                  <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
                </div>
              </div>
            </div>
          }
          {
            card.map((card) => (
              <div className="card" key={card._id}>
                <NavLink to={'/product/' + card._id} className="card__image-container">
                  <img className="card__image" src={card.image} alt={card.name} />
                </NavLink>
                <span className="card__price">{
                  // discount before price and after price Discount
                  card.discount > 0 ? (
                    <div>
                      <span style={
                        { textDecoration: 'line-through', color: 'red' }
                      }>{card.price}$ </span>
                      <span className="card__price--after">{(card.price - (card.price * card.discount / 100)).toFixed(2)}$</span>
                    </div>
                  ) : (
                    <span className="card__price--after">{card.price}$</span>
                  )
                }</span>
                <span className="card__name">{card.name}</span>
                <p className="qry">qry; {card.quantity}</p>
                <p>{card.category?.name}</p>
                <div className="edit">
                  <button onClick={() => {
                    card.image = card.image; setCardUpdate(card); setShow(!show); getAllSubCat(card.category?._id);
                     setModifiers(card.modifier || modifiers)
                  }}> &#10000; Edit</button>
                  <button onClick={() => { setDel(card); setShowDel(true) }}> &#9832; Delete</button>
                </div>
              </div>
            ))  // end map

          }

        </section>
      </div>
    </div>
  );
}


function Product() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Product;