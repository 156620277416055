import axios from "axios"

const billsUrl = '/api/v1/admin/bills/topProducts';

// let headers = { 'Content-Type': 'multipart/form-data' }
 
export const topProducts = async (id) => {
    id = id || '';
    return await axios.get(`${billsUrl}/${id}`);
}

export const addBills = async (Bills, atoken) => {
    return await axios.post(`${billsUrl}` , Bills, { headers: { token: atoken } });
}
export const updateBills = async (id, Bills, atoken) => {
    return await axios.put(`${billsUrl}/${id}`, Bills, { headers: { token: atoken } });
}


export const deleteBills = async (id, atoken) => {
    return await axios.delete(`${billsUrl}/${id}`, { headers: { token: atoken } });
}

export const editBills = async (id, Bills) => {
    return await axios.put(`${billsUrl}/${id}`, Bills)
}
 