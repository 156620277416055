import Header from '../../header/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './singup.scss';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../App';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Login from '../../Components/google';

const SignupSchema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const ValidationSchemaExample = () => {
  const user = useContext(ThemeContext);
  const navigate = useNavigate();
  const [error, setError] = useState();

  if (user.user != null) {
    navigate('/');
  }

  return (
    <div>
      <h1>
        Signup / <Link to={'/singin'}>login </Link>{' '}
      </h1>
      {/* <Login /> */}
      <Formik
        initialValues={{
          name: '',
          email: '',
          password: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          axios
          .post('/api/v1/users/register', values)
          .then(
            response => {
              console.log(response);
              user.setUser(response.data);
              navigate('/');
            },
            error => {
              console.log(error);
              if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
              } else {
                setError("An unknown error occurred.");
              }
            }
          );
        
        }}
      >
        {({ errors, touched }) => (
          <Form>
          {error ? <div className='error' >{error}</div> : null}
            <Field name="name" placeholder="Enter your name" />
            {errors.name && touched.name ? (
              <div>{errors.name}</div>
            ) : null}
            <Field name="email" type="email" placeholder="Enter your email" />
            {errors.email && touched.email ? (
              <div>{errors.email}</div>
            ) : null}
            <Field name="phone" placeholder="Enter your phone" />
            {errors.phone && touched.phone ? ( <div>{errors.phone}</div> ) : null}
            <Field
              name="password"
              type="password"
              placeholder="Enter your password"
            />
            {errors.password && touched.password ? (
              <div>{errors.password}</div>
            ) : null}
            <button type="submit">Signup</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

function Singup() {
  return (
    <div>
      <Header />
      <div className="form">
        <ValidationSchemaExample />
      </div>
    </div>
  );
}

export default Singup;
