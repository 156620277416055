import Header from "../../header/Header"
import { useState, useEffect, useContext } from "react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { allProduct } from '../../api/Product';
import { allCategory } from '../../api/Category';
import { allSubcatogery } from '../../api/SubCategory';
import { allBills, addBills } from '../../api/Bill';
import { allCustomers } from '../../api/Customers';
import Button from '@mui/material/Button';


import { Modal, makeStyles, FormControl, MenuItem, Select } from '@material-ui/core';
import { FaAngleDown, FaRegMoneyBillAlt, FaTrashAlt, FaCashRegister, FaMinus, FaPlus, FaPercent } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";

import './cashier2.scss'
import { ThemeContext } from '../../App';


import axios from "axios";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        maxHeight: '80vh',
    },
}));



const Casher = () => {

    const Theme = useContext(ThemeContext);

    const [site, setSite] = useState([]);
    const [card, setCard] = useState([]);
    const [category, setCategory] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [cartShow, setCartShow] = useState(false);
    const [show, setShow] = useState(false);
    const [payShow, setPayShow] = useState(false);
    const [discountShow, setDiscountShow] = useState(false);
    const [cusOpen, setCusOpen] = useState(false);
    const [bill, setBill] = useState([]);
    const [total, setTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [paid, setPaid] = useState(0);

    const [paidCash, setPaidCash] = useState(0);
    const [paidBank, setPaidBank] = useState(0);
    const [due, setDue] = useState(0);
    const [discountType, setDiscountType] = useState('0');
    const [discount, setDiscount] = useState(0);

    const { t, i18n } = useTranslation();


    const classes = useStyles();

    // function add to theme.Theme.cart
    const addCart = (item) => {
        const check = Theme.cart.find((i) => i._id === item._id);

        // play sound when add to cart 
        const audio = new Audio('/sound_beep-29.mp3');
        audio.play();

        if (check) {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: ++i.quantity } : i))

        } else {
            Theme.setCart([...Theme.cart, { ...item, quantity: 1, maxqrt: item.quantity }]);
        }
    }

    const updateCart = (item) => {
        Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: ++i.quantity } : i))
    }

    const downcart = (item) => {
        const check = Theme.cart.find((i) => i._id === item._id);
        if (check.quantity > 1) {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: --i.quantity } : i))
        }
    }


    const countUp = (item, num) => {
        const check = Theme.cart.find((i) => i._id === item._id);

        if (num == 'c') {
            // Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: i.quantity.toString().slice(0, -1) } : i)) and if quantity == 0 remove item from Theme.cart 
            // if (check.quantity.toString().length > 1) { Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString().slice(0, -1)) } : i)) } else { Theme.setCart(Theme.cart.filter((i) => i._id !== item._id)) }
            // qauntity make 0
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: 0 } : i))
        }
        else if (num == 'd') {
            // Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: i.quantity.toString().slice(0, -1) } : i)) and if quantity == 0 remove item from Theme.cart
            if (check.quantity.toString().length > 0) { Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString().slice(0, -1)) } : i)) }
        }
        else {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString() + num) } : i))
        }

    }


    useEffect(() => {
        getAllCat();
        getAllProduct();
        getAllCustomer();
        getAllBill();
    }, []);


    const getAllCat = async () => {
        let response = await allCategory();
        setCategory(response.data);
    }

    const getSubCat = async (e) => {
        let response = await allSubcatogery('category/' + e);
        setsubCategory(response.data);
    }



    const getAllProduct = async () => {
        let response = await allProduct();
        setCard(response.data.data);
        setSite(response.data.site);
    }
    // filter by category
    const filterCat = async (id) => {
        let response = await allProduct();
        let result = response.data.data.filter((item) => item.category._id === id);
        setCard(result);
        getSubCat(id)
    }
    // filter by subcategory
    const filterSubCat = async (id) => {
        let response = await allProduct();
        let result = response.data.data.filter((item) => item.subcategory === id);
        setCard(result);
    }

    const getAllCustomer = async () => {
        let response = await allCustomers();
        setCustomer(response.data);
    }

    const getAllBill = async () => {
        let response = await allBills();
        setBill(response.data);
    }




    useEffect(() => {
        // let total = Theme.cart.reduce((a, b) => a + (b.price * b.quantity), 0); add taxz
        let netTotal = Theme.cart.reduce((a, b) => a + (b.price * b.quantity) - (b.discount * b.quantity), 0);
        let paid = Theme.cart.reduce((a, b) => a + (b.price * b.quantity), 0);
        let tax ;
        if (discountType === 'percent') {
            netTotal = netTotal - (netTotal * discount / 100);
            // paid = paid - (paid * discount / 100); // add site.tax % to paid
            paid = paid - (paid * discount / 100) + (paid * discount / 100 * site.tax / 100); 
            tax = paid * discount / 100 * site.tax / 100;
        }
        else {
            netTotal = netTotal - discount;
            // paid = paid - discount;
            paid = paid - discount + (paid * site.tax / 100);
            tax = paid * site.tax / 100;
        }
        setDiscount(discount);
        setDiscountType(discountType);
        setTotal(total);
        setNetTotal(netTotal);
        setPaid(paid);
        setTax(tax);
    }, [discount, discountType, Theme.cart])



    const addbill = async (e) => {
        e.preventDefault();
        // check if cart is empty
        if (Theme.cart.length === 0) {
            return
        }
        console.log(Theme.cart.length);
        const products = Theme.cart.map((item) => {
            return {
                product: item._id,
                name: item.name,
                category: item.category._id,
                quantity: item.quantity,
                price: item.price,
                purchase: item.purchase,
                tax: item.tax,
                note: item.note,
            }
        })

        let response = await addBills({
            id: e.target.id.value,
            user: Theme.user._id,
            customer: Theme.customer?._id || Theme.customer,
            products: products,
            mark: e.target.mark.value,
            total: total,
            discountType: discountType,
            discount: discount,
            tax: tax,
            netTotal: netTotal,
            qrCode:site,
            payment: [
                { method: 'Cash', amount: paidCash },
                { method: 'Bank', amount: paidBank }
            ],
            paid: paid,
            // paymentMethod: e.target.pay.value,
            status: "Paid",
            note: e.target.note.value
        });
        console.log(response);

        // fetch('/onprint/' + item._id, { method: 'GET' })
        fetch('/onprint/' + response.data._id, { method: 'GET' })
        Theme.setCart([]);
        Theme.setCustomer({});
        getAllCustomer();
        setDiscount(0);

        const audio = new Audio('/removing.mp3');
        audio.play();

    }




    let selectCustomer = async (item) => {
        Theme.setCustomer(item);

    }

    let searchCustomer = async (e) => {
        let response = await allCustomers(e.target.value);
        setCustomer(response.data);
        setCusOpen(true);
    }



    // create function to paymnet method cash or bank or bank and cash
    let paymentMethod = (method, amount) => {
        if (method === 'Cash') {
            setPaidCash(amount);
            setPaidBank(paid - amount);
        }
        else if (method === 'Bank') {
            setPaidBank(amount);
            setPaidCash(paid - amount);
        }

    }



    return (
        <div className="casher">




            <div className="casher__header">
                <button className="casher__header__btn" onClick={() => setCartShow(!cartShow)}>
                    <div className="item">

                        <div className="invoice__total">
                            <FaCashRegister color="blue" />
                            <span>{t('Total')} </span>
                            <span>{total}</span>
                        </div>
                        <div className="total__qrt">
                            <span>{t('qrt')} </span>
                            <span>{Theme.cart.reduce((a, b) => a + (b.quantity), 0)}</span>
                        </div>
                        <div className="invoice__custem">
                            <span>{t('Customer')} </span>
                            <span>{Theme.customer?.name}</span>
                        </div>
                        <div className="invoice__custem">
                            <span>{t('disc')} </span>
                            <span>{discountType === 'percent' ? discount + '%' : discount + ' '+site.currency}</span>
                        </div>
                    </div>
                </button>


                <div className="casher__left">


                    <form className="invoice" onSubmit={(e) => addbill(e)} >
                        {/* remove cart */}
                        <button onClick={() => Theme.setCart([])} className='btn-remove'><FaTrashAlt /> All</button>
                        <input type="submit" value="submit" id="form" hidden />

                        <div className="invoice__pay" onClick={() => {setPayShow(!payShow), paymentMethod('Cash',paid) } }>
                            <span> <FaRegMoneyBillAlt /> {t('Pay')} </span>
                            <span className="total">{total}</span>
                            <span>{paid}</span>
                        </div>

                        <Modal
                            open={payShow}
                            onClose={() => setPayShow(false)}
                            className={classes.modal}
                        >
                            <div className={classes.paper}>

                                <div className="payCashier" >
                                    <div className="cash">
                                        <span onClick={(e) => paymentMethod('Cash', paid)}>{t('Cash')} </span>
                                        <input type="text" name="paidCash" value={paidCash} onChange={(e) => paymentMethod('Cash', paid >= e.target.value ? e.target.value : paid)} />
                                    </div>
                                    <div className="bank">
                                        <span onClick={(e) => paymentMethod('Bank', paid)}>{t('Bank')} </span>
                                        <input type="text" name="paidBank" value={paidBank} onChange={(e) => paymentMethod('Bank', paid >= e.target.value ? e.target.value : paid)} />
                                    </div>

                                    <div className="item">
                                        <div className="invoice__total">
                                            <FaCashRegister color="blue" />
                                            <span>{t('Total')} </span>
                                            <span>{total}</span>
                                        </div>
                                        <div className="total__qrt">
                                            <span>{t('Paid')} </span>
                                            <span>{paid}</span>
                                        </div>
                                        <div className="invoice__custem">
                                            <span>{t('Discount')} </span>
                                            <span>{discountType === 'percent' ? discount + '%' : discount + '$'}</span>
                                        </div>
                                        <div className="tax">
                                            <span>{t('Tax')} </span>
                                            <span>%{site.tax}</span>
                                        </div>
                                    </div>

                                    <button className="invoice__pay" onClick={() => (document.getElementById('form').click(), setPayShow(false))}>
                                        <span> <FaRegMoneyBillAlt /> {t('Pay')} </span>
                                        <span className="total">{total}</span>
                                        <span>{paid}</span>
                                    </button>

                                </div>
                            </div>
                        </Modal>

                        <FormControl className=" ">
                            <Select name="mark" required defaultValue="takeaway">
                                <MenuItem value="takeaway">{t('takeaway')}</MenuItem>
                                <MenuItem value="delivery">{t('delivery')}</MenuItem>
                                <MenuItem value="dining">{t('dining')}</MenuItem>
                            </Select>
                        </FormControl>

                        <Modal
                            open={discountShow}
                            onClose={() => setDiscountShow(false)}
                            className={classes.modal}
                        >
                            <div className={classes.paper}>

                                <div className="discountByCashier" >

                                    <div className="item">
                                        <div className="invoice__total">
                                            <FaCashRegister color="blue" />
                                            <span>{t('Total')} </span>
                                            <span>{total}</span>
                                        </div>
                                        <div className="total__qrt">
                                            <span>{t('Paid')} </span>
                                            <span>{paid}</span>
                                        </div>
                                        <div className="invoice__custem">
                                            <span>{t('Discount')} </span>
                                            <span>{discountType === 'percent' ? discount + '%' : discount + ' '+ site.currency}</span>
                                        </div>
                                    </div>

                                    <Button variant="contained" onClick={() => setDiscountType('percent')}> <FaPercent /> </Button>
                                    <input name="discount" placeholder={t('Discount')} onChange={(e) => setDiscount(e.target.value)} />
                                    <Button variant="contained" onClick={() => setDiscountType('fixed')}> {site.currency}</Button>

                                    {/* <div className="type_radio">
                                        <div className="mark">
                                            <input type="radio" id="delivery" name="mark" value="delivery" required />
                                            <label htmlFor="delivery">{t('delivery')}</label>
                                        </div>
                                        <div className="mark">
                                            <input type="radio" id="takeaway" name="mark" value="takeaway" checked />
                                            <label htmlFor="takeaway">{t('takeaway')}</label>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </Modal>


                        <div className="discountByCashier" >
                            <Button variant="contained" color="error" onClick={() => setDiscountShow(!discountShow)}> <FaPercent /> </Button>

                        </div>

                        <div className="cuscash">

                            <div className="customer">


                                <div className="list">
                                    <div className="drop" onClick={() => { setCusOpen(!cusOpen) }}>
                                        <h3>   <input type="text" onChange={searchCustomer} placeholder={Theme.customer?.name ? Theme.customer?.name : t('Customer')} /> <FaAngleDown />  </h3>

                                        <div className="drop__content">
                                            {cusOpen && customer.map((item, index) => (
                                                <div className="drop__item" key={index} onClick={() => selectCustomer(item)}>
                                                    <p>{item.name}</p>
                                                    <p className="number">{item.phone?.split(",").map(str => <div key={str}>{str}</div>)}</p>
                                                </div>
                                            ))}
                                            {cusOpen && customer.length === 0 && <NavLink to="/admin/customer" className="customer__add"> <IoAddCircleOutline size={28} /> {t('Customer')}</NavLink>}

                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>

                        <input type="text" name="note" className="note w-100" placeholder={t('note')} />

                    </form>





                    <div className="cartbxs">
                        {
                            Theme.cart.map((item, index) => {
                                return (
                                    <div key={index} className='cartbx'>
                                        <div className="cx">
                                            <div className="info">
                                                <div className="info__item">
                                                    <div className="quantity">qty {item.quantity}  </div>
                                                    <div className="price">price {item.price}  </div>
                                                    <div className="total">total {item.price * item.quantity}  </div>
                                                </div>
                                                <div className="name">
                                                    <div>
                                                        {/* <img src={item.image} width="22" height='22' />  */}
                                                        <span>{item.name}</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="qry">
                                            <button onClick={() => { updateCart(item) }} className='btn btn-success'>< FaPlus /> </button>
                                            {/* <input type="number" value={item.quantity} onClick={(e) => e.target.select()} onChange={(e) => Theme.setCart([...Theme.cart.slice(0, index), { ...item, quantity: e.target.value }, ...Theme.cart.slice(index + 1)])} /> */}
                                            <span>{item.quantity}</span>
                                            <button onClick={() => downcart(item)} className='btn btn-warning'> <FaMinus /> </button>
                                            <button onClick={() => Theme.setCart([...Theme.cart.slice(0, index), ...Theme.cart.slice(index + 1)])} className='btn btn-danger'><FaTrashAlt /></button>

                                            <div className="keyword-toutch">
                                                <div>

                                                    <button onClick={() => countUp(item, 1)}>1</button>
                                                    <button onClick={() => countUp(item, 2)}>2</button>
                                                    <button onClick={() => countUp(item, 3)}>3</button>
                                                    <button onClick={() => countUp(item, 4)}>4</button>
                                                    <button onClick={() => countUp(item, 5)}>5</button>
                                                    <button onClick={() => countUp(item, 6)}>6</button>
                                                    <button onClick={() => countUp(item, 7)}>7</button>
                                                    <button onClick={() => countUp(item, 8)}>8</button>
                                                    <button onClick={() => countUp(item, 9)}>9</button>
                                                    <button onClick={() => countUp(item, 0)}>0</button>
                                                    <button onClick={() => countUp(item, 'd')}>back</button>
                                                    <button onClick={() => countUp(item, 'c')}>clear</button>

                                                </div>
                                            </div>

                                        </div>
                                        <input type="text" name="note" placeholder={t('note')} onChange={(e) => Theme.setCart([...Theme.cart.slice(0, index), { ...item, note: e.target.value }, ...Theme.cart.slice(index + 1)])} />

                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

            </div>

            <div className="casher__right">

                <div className="catg">
                    {category.map((cat) => (
                        <p key={cat._id} value={cat._id} onClick={() => filterCat(cat._id)} >
                            <img src={cat.image} alt="" />
                            <span>{cat.name}</span>

                        </p>
                    ))}
                </div>
                <div className="catgs">
                    {subCategory.length > 0 && <p>sub menu </p>}
                    <div className="catg">
                        {subCategory.length > 0 && subCategory.map((sub) => (
                            <p key={sub._id} value={sub._id} onClick={() => filterSubCat(sub._id)}>
                                <img src={sub.image ? sub.image : sub.category.image} alt="" />
                                <span>{sub.name}</span>
                            </p>
                        ))}
                    </div>
                </div>
                <section className="cards-cashier">
                    {
                        card.length > 0 ?
                            card.map((card, index) => (

                                <div className="card" key={card._id} onClick={() => { addCart(card) }}>
                                    <img className="card__image" src={card.image} />
                                    <span className="card__price">{
                                        // discount before price and after price Discount
                                        card.discount > 0 ? (
                                            <div>
                                                <span style={
                                                    { textDecoration: 'line-through', color: 'red' }
                                                }>{card.price}$ </span>
                                                <span className="card__price--after">{(card.price - (card.price * card.discount / 100)).toFixed(2)}$</span>
                                            </div>
                                        ) : (
                                            <span className="card__price--after">{card.price}</span>
                                        )
                                    }</span>
                                    <span className="card__name">{card.name}</span>
                                    {/* <p className="qry">qry; {card.quantity}</p> */}
                                </div>
                            ))  // end map
                            : <h1>{t('no product')}</h1>

                    }
                </section>

            </div>
        </div>
    );
}


export default () => {

    return (
        <main>
            <Header />
            <Casher />
        </main>
    )
}

