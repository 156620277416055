import Header from '../../header/Header';
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt,FaLuggageCart,FaSearchDollar,FaDonate,FaMoneyBillWave} from "react-icons/fa";

import Chart from "chart.js/auto";
import { Line, Bar, Pie, } from "react-chartjs-2";

import './dashboard.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allCategory } from '../../api/Category';
import { topProducts } from '../../api/admin/billAdmin';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';




function List() {
  const Theme = useContext(ThemeContext);
  const [card, setCard] = useState([]);

const [t , i18n] = useTranslation();

useEffect(() => {
  topProducts().then((res) => {
    setCard(res.data);

  });
}, []);

const getTopProducts = async (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  const data = Object.fromEntries(formData.entries());
  let { start, end } = data;
  start = start || new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
  end = end || new Date().toISOString().slice(0, 10);
  const response = await topProducts(`${start}/${end}`);
  if (response.status === 200) {
    setCard(response.data);
  }
};



  return (
    <div className='dashboard' >
      <Menu />
      <div className='body'>
        <h1><FaTachometerAlt /> {t('dashboard')} </h1>
        <p> {t('overview of your sales, and purchases and profit')} </p>
          <div className="bar" >
            <form id="data-sel" onSubmit={ getTopProducts } >
              <label for="start">{t('from')}:</label>
              <input type="date" id="start" name="start" onChange={ getTopProducts }  />
             
              <label for="start">{t('to')}:</label>
              <input type="date" id="end" name="end"   />
             <button type='supmit'> <FaSearchDollar size={28}/></button>
            </form>

        </div>
        <div className="data">
          <div className="data-item Sales">
            <h1> <FaLuggageCart/> {card.reduce((a, b) => a + b.total, 0)} </h1>
            <p> {t('total Sales')} </p>
          </div>
          <div className="data-item Profite">
            <h1> <FaDonate/> {card.reduce((a, b) => a + b.totalProfit, 0)} </h1>
            <p> {t('Total Profite')} </p>
          </div>
          <div className="data-item Amount">
            <h1> <FaMoneyBillWave/> {card.reduce((a, b) => a + b.totalAmount, 0)} </h1>
            <p> {t('Total Amount')} </p>
          </div>


        </div>
        
        <div>
          <table>
            <tr> <th> {t('name')} </th> <th> {t('image')} </th> <th> {t('total sell')} </th> <th> {t('totalAmount')} </th> <th> {t('totalProfit')} </th> </tr>
            {
              card.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {item.product.name} </td>
                    <td> <img src={item.product.image} alt="" /> </td>
                    <td> {item.total} </td>
                    <td> {item.totalAmount} </td>
                    <td> {item.totalProfit} </td>
                  </tr>
                )
              }
              )
            }
          </table>
          </div>
      </div>
    </div>
  );
}


function Product() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default Product;