// Invoice
// This component is used to display the invoice information
// 
import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from "react-dom"
import { FaPrint } from "react-icons/fa";
// import bill from '../../api/Bill';
import { allBills, addBills } from '../api/Bill';
import QRCode from "react-qr-code";

import { ThemeContext } from '../App';
import './Invoice.scss';

const Invoice = ({ item }) => {
    const Theme = useContext(ThemeContext);
    const [bill, setBill] = useState([]);

    const [show, setShow] = useState(false);

    useEffect(() => {
        getAllBill();
    }, [item._id]);

    const getAllBill = async () => {
        let response = await allBills(item._id);
        setBill(response.data);
    }


    return (


        <div className="invoice bg-light p-2">

            <div className="print btn btn-primary" onClick={() => window.print()}>
                <FaPrint size={28} />
            </div>

            <div>


                <div className="modal-body">
                    <div className="invoice__body" id='print'>
                        <div className="invoice__body__left">

                            {
                                bill.bill?.status && bill.bill?.status === 'Void' && <div className="invoice__body__left__status">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>date</th>
                                                <th>Void</th>
                                                <th>User</th>
                                                <th>Note</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{bill.bill?.void?.createdAt && bill.bill?.void?.createdAt.slice(0, 10) + ' ' + bill.bill?.void?.createdAt.slice(11, 19)}</td>

                                                <td>{bill.bill?.status}</td>
                                                <td>{bill.bill?.void?.user}</td>
                                                <td>{bill.bill?.void?.note}</td>
                                                <td>{bill.bill?.void?.reason}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            }
                            <p>{bill.site && bill.site.name}</p>
                            <img src={bill.site && bill.site.logo} alt="" width={150} />
                            <p>address: {bill.site && bill.site.address}</p>
                            <p>phone: {bill.site && bill.site.phone}</p>
                            <p>email: {bill.site && bill.site.email}</p>

                            <h1>Invoice: {bill.bill?.mark}</h1>
                            <p>No: {bill.bill?._id}</p>
                            <p>Date : {bill.bill?.createdAt && bill.bill?.createdAt.slice(0, 10) + ' ' + bill.bill?.createdAt.slice(11, 19)}</p>

                            <p>payment {bill.bill?.payment.map((item, index) => {
                                return (
                                    item.amount > 0 &&
                                    <span key={index}>
                                        <span>{item.method} </span>
                                        <span>{item.amount} </span>
                                    </span>
                                )
                            })}</p>

                            <p>cashier : {bill.bill?.user && bill.bill?.user?.name}</p>

                            <p>
                                <span>Customer: {bill.bill?.customer && bill.bill?.customer?.name}</span>
                                <span> Phone: {bill.bill?.customer && bill.bill?.customer?.phone}</span>
                            </p>
                            <p>
                                <span>Address: {bill.bill?.customer && bill.bill?.customer?.address}</span>
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>qrt</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bill.bill?.products && bill.bill?.products.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p>{item.name}</p>
                                                <p>{item.note}</p>

                                                {
                                                    // JSON.stringify(item.product )
                                                }
                                            </td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}</td>
                                            <td>{item.quantity * item.price}</td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Total</th>
                                        <th>Dic</th>
                                        <th>Net Total</th>
                                        <th>Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{bill.bill?.total}</td>
                                        <td>{bill.bill?.discount}</td>
                                        <td>{bill.bill?.netTotal}</td>
                                        <td>{bill.bill?.paid}</td>
                                    </tr>
                                </tbody>

                            </table>
                            <div className="note">{bill.bill?.note}</div>
                            <QRCode size={222} value={bill?.bill?.qrCode || 'null'} />
                        </div>

                    </div>
                </div>

            </div>



        </div>
    )
}


export default Invoice