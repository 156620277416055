import axios from "axios";

const siteUrl = '/api/v1/site';


 
export const allSite = async (id) => {
    id = id || '';
    return await axios.get(`${siteUrl}/${id}`);
}

export const addSite = async (site, atoken) => {
    return await axios.post(`${siteUrl}`, site, { headers: { token: atoken } });
}

export const deleteSite = async (id, atoken) => {
    return await axios.delete(`${siteUrl}/${id}`, { headers: { token: atoken } });
}

export const editSite = async (id, site, atoken) => {
    return await axios.put(`${siteUrl}/${id}`, site, { headers: { token: atoken } });
}

