import Header from '../../header/Header';
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { FaUsersCog } from "react-icons/fa";

import './dashboard.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allUser, addUser, deleteUser, editUser } from '../../api/User';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';


import React, { useState, useEffect } from 'react';


function List() {
  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);

  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);




  useEffect(() => {
    getAllCat();
    getallUser();
  }, []);

  const deletePro = async (id) => {
    await deleteUser(id, Theme.user.accessToken);
    getAllCat();
  }
  const getallUser = async () => {
    let response = await allUser();
    setCard(response.data);
    console.log(response.data, 'response');
  }

  const getAllCat = async () => {
    let response = await allUser();
    setUser(response.data);
  }

  console.log(Theme.user.accessToken);

  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    console.log(formData);
    // const data = Object.fromEntries(formData.entries());
    // let response = await addProd(formData, Theme.user.accessToken);
    let response = cardUpdate._id ? await editUser(cardUpdate._id, formData, Theme.user.accessToken) : await addUser(formData, Theme.user.accessToken);
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllCat();
      setShow(false);
    } else {
      toast.error('Something went wrong');
    }

  }

  return (
    <div className='dashboard' >
      <Menu />
      <div className='body'>
        <h1> <FaUsersCog size={28} /> Users</h1>
        <p> overview of your users </p>
        <ToastContainer />

        {/* <div className='add' onClick={() => { setShow(!show); setCardUpdate([]) }}><IoAddCircleOutline size={28} /> add Catgory </div> */}
        <div className="form">
          {
            show && ReactDOM.createPortal(<div id="form__add">
              <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

              <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
                <input type="text" name='_id' value={cardUpdate._id} hidden />
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                  } />
                </div>
                {/* update role */}
                <div className="form-group">
                  <label htmlFor="role">role</label>
                  <br />
                  <select name='role' defaultValue={cardUpdate.role?._id}>
                    <option value='user'>user</option>
                    <option value='chef'>chef</option>
                    <option value='cashier'>cashier</option>
                    <option value='admin'>admin</option>
                    <option value='superadmin'>super admin</option>
                  </select>

                </div>

                <div className="form-group">
                  <label htmlFor="image">image</label>
                  <div id="im">
                    <img src={
                      cardUpdate.imageUrl ? cardUpdate.imageUrl : '/upload.png'
                    } alt='upload' />
                    <input type="file" name="image" id="image" placeholder='image' onChange={
                      (e) => setCardUpdate({ ...cardUpdate, imageUrl: URL.createObjectURL(e.target.files[0]) })
                    } />
                  </div>
                </div>
                <button type="submit">{cardUpdate._id ? 'Update' : 'Add'}</button>


              </form>
            </div>, document.getElementById('modal'))
          }

        </div>

        <section className="cards">
          {
            showDel &&
            <div className="del">
              <div className="body">
                <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
                <div className="del__card">
                  <p>Are you sure you want to delete this User?</p>
                  <h2>{del.name}</h2>
                  <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
                </div>
              </div>
            </div>
          }
          <table>
            <thead>
              <tr> <th>name</th> <th>image</th> <th>role</th> <th>edit</th> </tr>
            </thead>
            <tbody>
              {
                user.map((card) => (
                  <tr key={card._id}>
                    <td>{card.name}</td>
                    <td><img src={card.imageUrl} alt="card" className="card__img" /></td>
                    <td>{card.role}</td>
                    <td><button onClick={() => { setCardUpdate(card); setShow(true) }}> &#10000; Edit</button></td>
                  </tr>
                ))
              }
            </tbody>

          </table>
        </section>
      </div>
    </div>
  );
}


function User() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default User;