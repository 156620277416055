import axios from "axios"

 


const subcatogeryUrl = '/api/v1/subcategories';

 
export const allSubcatogery = async (id) => {
    id = id || '';
    return await axios.get(`${subcatogeryUrl}/${id}`);
}

export const addSubcatogery = async (subcatogery, atoken) => {
    return await axios.post(`${subcatogeryUrl}`, subcatogery, { headers: { token: atoken } });
}

export const deleteSubcatogery = async (id, atoken) => {
    return await axios.delete(`${subcatogeryUrl}/${id}`, { headers: { token: atoken } });
}

export const editSubcatogery = async (id, subcatogery, atoken) => {
    return await axios.put(`${subcatogeryUrl}/${id}`, subcatogery, { headers: { token: atoken } });
}

