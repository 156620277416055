

import axios from "axios"
 


const userUrl = '/api/v1/users';

 
export const allUser = async (id) => {
    id = id || '';
    return await axios.get(`${userUrl}/${id}`);
}

export const addUser = async (user, atoken) => {
    return await axios.post(`${userUrl}`, user, { headers: { token: atoken } });
}

export const deleteUser = async (id, atoken) => {
    return await axios.delete(`${userUrl}/${id}`, { headers: { token: atoken } });
}

export const editUser = async (id, user, atoken) => {
    return await axios.put(`${userUrl}/${id}`, user, { headers: { token: atoken } });
}
    