

import axios from "axios"
 


const customersUrl = '/api/v1/customers';

 
export const allCustomers = async (id) => {
    id = id || '';
    return await axios.get(`${customersUrl}/${id}`);
}

export const addCustomers = async (customers, atoken) => {
    return await axios.post(`${customersUrl}`, customers, { headers: { token: atoken } });
}

export const deleteCustomers = async (id, atoken) => {
    return await axios.delete(`${customersUrl}/${id}`, { headers: { token: atoken } });
}

export const editCustomers = async (id, customers, atoken) => {
    return await axios.put(`${customersUrl}/${id}`, customers, { headers: { token: atoken } });
}
