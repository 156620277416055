
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { FaRegAddressCard } from "react-icons/fa";
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';

import io from 'socket.io-client';
import { ThemeContext } from '../App';
import './Notification.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const Notification = () => {
  const theme = useContext(ThemeContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const socket = io();

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('acceptOpenTable', (data) => {
      new Audio('/triangle_open_01-102876.mp3').play();
      console.log(`Received echo: ${data}`);
      getAllTable();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getAllTable = async () => {
    try {
      const response = await axios.get(`/api/v1/tables/items/accept`);
      setNotifications(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllTable();
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAccept = async (id) => {
    try {
      await axios.put(
        `/api/v1/tables/items/accept`,
        {
          status: 'accept',
          id: id,
        },
        { headers: { token: theme.user.accessToken } }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.table._id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/tables/items/accept/${id}`, {
        headers: { token: theme.user.accessToken },
      });
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n._id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const [status, setStatus] = useState('');



  return (
    <div className={classes.root}>
      <IconButton
        aria-label="notifications"
        color="inherit"
        onClick={handleMenuOpen}
      >
        <Badge
          badgeContent={notifications.filter((n) => n.status === 'Pending').length}
          color="secondary"
        >
          <FaRegAddressCard />
        </Badge>
        <Badge
          badgeContent={notifications.filter((n) => n.status === 'check' || n.status === 'Captain' ).length}
          color="primary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <h1 style={{ padding: '5px' }}> Notifications </h1>
        <nav className="tab">
          <div
            className={`tab-item${status === "" ? " active" : ""}`}
            onClick={() => setStatus('')}
          >
            all <span>{ notifications.length }</span>
          </div>
          <div
            className={`tab-item${status === "Pending" ? " active" : ""}`}
            onClick={() => setStatus("Pending")}
          >
            Pending <span>{ notifications.filter((n) => n.status === 'Pending').length }</span>
          </div>
          <div
            className={`tab-item${status === "check" ? " active" : ""}`}
            onClick={() => setStatus("check")}
          >
            Check <span>{ notifications.filter((n) => n.status === 'check').length }</span>
          </div>
          <div
            className={`tab-item${status === "Captain" ? " active" : ""}`}
            onClick={() => setStatus("Captain")}
          >
            Captain <span>{ notifications.filter((n) => n.status === 'Captain').length }</span>
          </div>
        </nav>
        {notifications.filter((n) => status === "" || n.status === status).map(({ _id,price, table, user, status }) => (
          <MenuItem key={_id} className="notification">
            <Stack direction="row" spacing={2} className=' stack-d '>
              <Avatar sx={{ bgcolor: deepOrange[500] }}>
                T {table.tableNumber}
              </Avatar>
              {/* <div className="table">{table.name} {table.tableNumber}</div> */}

              <div className="name">{user.name}</div>
              <div className="status">{status}</div>
              <div className="price">price {price.toFixed(2)}</div>
              <div className="accept" onClick={() => handleAccept(_id)}> 
                { status === 'Pending' ? 'تأكيد فتح الطاولة' : status === 'check' ? 'إعادة فتح الطاولة' : status === 'Captain' ? 'تم ارد وإعادة فتح الطاولة' : 'Accept'}
              
              </div>
              <div className="delete" onClick={() => handleDelete(_id)}>
                {/* { status === 'Pending' ? 'Delete' : 'Cancel'} */}
                { status === 'Pending' ?  'الغاء' : status === 'check' ? 'محسبة وغلق الطاولة' :  status === 'Captain' ? 'تم الغاء الطاولة' : 'غلق الطاولة'} 
              </div>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Notification;