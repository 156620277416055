import './Header.scss'
import { NavLink } from 'react-router-dom';
import React, { useContext } from 'react';
import { GoogleLogout } from 'react-google-login';
import { ThemeContext } from '../App';
import { BsCashStack, BsCreditCard2Back } from "react-icons/bs";
import { FaLanguage } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DropMenuNotification from './Notification';



const Header = () => {
  const Theme = useContext(ThemeContext);
  const clientId = "1038695984787-1p4udh37usv0poigeh96bh31l3t0uasr.apps.googleusercontent.com";
  // logout function 
  const logout = () => {
    Theme.setUser(null);
    localStorage.removeItem('user');
  }
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <>
      <header>
        <NavLink to={'/'} className="logo">
          <img src="/logo.jpg" alt="" />
          <span>Faster Cashier</span>
        </NavLink>
        <div className="menu">
          <nav>

            <div className="sing">

              <div className="lang">
                <FaLanguage size={34} />
                <select className="btn btn-lang" onChange={(e) => changeLanguage(e.target.value)}>
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </select>
              </div>

              {
                Theme.user != null ?
                  <div className="useer">
                    <div className="avatar">
                      <img src={Theme.user.imageUrl || '/user1.png'} alt="avatar" />
                    </div>
                    <div className="dropmenu">
                      <p> last Login: {
                        Theme.user.lastLogin ? new Date(Theme.user.lastLogin).toLocaleString() : new Date(Theme.user.createdAt).toLocaleString() || 'No login'
                      }</p>
                      <span>{Theme.user.name}</span>
                      {
                        Theme.user.googleId != null ?
                          <GoogleLogout clientId={clientId} buttonText="Logout" onLogoutSuccess={logout} ></GoogleLogout>
                          :
                          <button onClick={logout}> logout</button>
                      }
                    </div>
                  </div>
                  :
                  <div className="singup" >
                    <NavLink to={'/Singup'} className='' >singup </NavLink>
                    <NavLink to={'/Singin'}> singin</NavLink>
                  </div>

              }
            </div>
          </nav>
        </div>
      </header>
      <div className="nav_header">

        {
          Theme.user && Theme.user.isAdmin && (
            <div className="admin">
              {
                Theme.user.role === 'admin' ? (
                  <>
                    <NavLink to="/admin/dashboard" className="dashboard"> <img src="/OIG.jpg" alt="" srcset="" /> <p>{t('dashboard')}</p> </NavLink>
                    {/* <NavLink to="/admin/bill" className="bill"> <span>&#9999;</span> <p>{t('bill')}</p> </NavLink> */}
                  </>
                ) : null
              }
              {
                Theme.user.role === 'admin' || Theme.user.role === 'chef' ? (
                  <>
                    <NavLink to="/kitchen" className="kitchen"> <img src="/OIG1.jpg" alt="" srcset="" /> <p>{t('kitchen')}</p> </NavLink>
                  </>
                ) : null
              }
              {
                Theme.user.role === 'admin' || Theme.user.role != 'cashier' ? (
                  <>
                    <DropMenuNotification className="notification" />
                    {/* <NavLink to="/admin/customer" className="customer"> <span><BsCreditCard2Back /></span> <p>{t('Customer')}</p> </NavLink>
                        <NavLink to="/cashier" className="cashier"> <span><BsCashStack /></span> <p>{t('cashier')}</p> </NavLink>
                        <NavLink to="/dining" className="dining"> <span>&#9999;</span> <p>{t('dining')}</p> </NavLink> */}
                    <NavLink to="/admin/table" className="table"> <img src="/table0.jpg" alt="" srcset="" /> <p>{t('table')}</p> </NavLink>
                    {/* <NavLink to="/cashier">
                          <img src="/cart.png" alt="" />
                          <span className="cartN">{Theme.cart.reduce((total, item) => total + item.quantity, 0)}</span>
                        </NavLink> */}
                  </>
                ) : null
              }
            </div>
          )
        }
      </div>


    </>
  )
}

export default Header