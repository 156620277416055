import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FaBeer } from 'react-icons/fa';
import { RiStore2Line, RiApps2Line, RiWallet3Line, RiPulseLine } from "react-icons/ri";
import { FaUsersCog } from "react-icons/fa";
import './menu.scss'

import { BsCashStack,BsCreditCard2Back } from "react-icons/bs";
// get url path and set active class
const active = (path) => {
    if (path in window.location.pathname) {
        return 'active';
    }
    return '';
}


const Menu = () => {
    const { t, i18n } = useTranslation();
    
    return <div className="menuP">

        <div className="menu">
            <NavLink to={'/admin/dashboard'} className={active+' dashboard'}>
                <RiStore2Line size={28} /> <span>{t('dashboard')}</span>
            </NavLink>
            <NavLink to={'/admin/table'} className={active+' table'}>
                <BsCashStack size={28} /> <span>{t('table')}</span>
            </NavLink>
            <NavLink to={'/admin/product'} className={active+' `product`'}>
                <RiWallet3Line size={28} /> <span>{t('Product')}</span>
            </NavLink>
            <NavLink to={'/admin/category'} className={active+' category'}>
                <RiApps2Line size={28} /> <span>{t('Category')}</span>
            </NavLink>
            <NavLink to={'/admin/subcategory'} className={active+' subcategory'}>
                <RiPulseLine size={28} /> <span>{t('Subcategory')}</span>
            </NavLink>
            <NavLink to={'/admin/user'} className={active+' user'}>
                <FaUsersCog size={28} /> <span>{t('Users')}</span>
            </NavLink>
            <NavLink to={'/admin/printer'} className={active+' printer'}>
                <FaUsersCog size={28} /> <span>{t('Printer')}</span>
            </NavLink>
            <NavLink to={'/admin/site'} className={active+' site'}>
                <FaBeer size={28} /> <span>{t('Site')}</span>
            </NavLink>
            <NavLink to={'/admin/log'} className={active+' log'}>
                <FaBeer size={28} /> <span>{t('Log')}</span>
            </NavLink>
        </div>
        <div className="setting">

            {/* <div className="menu">

                <button>
                    <RiStore2Line size={28} /> <span>febar</span>
                </button>
                <button>
                    <RiApps2Line size={28} /> <span>febar</span>
                </button>
                <button>
                    <RiWallet3Line size={28} /> <span>febar</span>
                </button>
                <button>
                    <RiPulseLine size={28} /> <span>febar</span>
                </button>
            </div> */}

        </div>
    </div>
}


export default Menu;
