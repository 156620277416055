import Header from "../../header/Header"
import { useParams, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { allProduct } from '../../api/Product';
import { allCategory } from '../../api/Category';
import { allSubcatogery } from '../../api/SubCategory';
import { allCustomers } from '../../api/Customers';

import Button from '@mui/material/Button';


import { Modal, makeStyles, FormControl, MenuItem, Select } from '@material-ui/core';
import { FaAngleDown, FaRegMoneyBillAlt, FaTrashAlt, FaCashRegister, FaMinus, FaPlus, FaPercent, FaArrowLeft, FaElementor, FaDribbble, FaTimes } from "react-icons/fa";
import { IoFastFoodOutline } from "react-icons/io5";

import './cashier2.scss'
import io from 'socket.io-client';
import { ThemeContext } from '../../App';
import Sing_in_out from '../sing/Sing_in_up';
import { ToastContainer, toast } from 'react-toastify';


import axios from "axios";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'scroll',
        maxHeight: '80vh',
    },
}));

import { useState, useEffect, useContext, useMemo, useCallback } from "react";

// accordion
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Kitchen = () => {
    const Theme = useContext(ThemeContext);
    const { t, i18n } = useTranslation();

    const [tables, setTables] = useState([]);
    const [table, setTable] = useState([]);
    const [cartShow, setCartShow] = useState(false);
    const { id } = useParams();

    const classes = useStyles();
    const getAllTable = useCallback(async () => {

        let response = await axios.get(`/api/v1/tables/items/user/${id}`, {
            headers: { token: Theme.user.accessToken }
        });
        setTables(response.data.tableItems);
        setTable(response.data.table);

    }, []);

    useEffect(() => {
        getAllTable();
    }, [getAllTable]);

    useEffect(() => {
        new Audio('/bottleput.mp3').play();
    }, [tables.length]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getAllTable();
        }, 111111000);
        return () => clearInterval(intervalId);
    }, [getAllTable]);

    const getTableId = useCallback(async (card) => {
        await axios.put(`/api/v1/tables/items/${card._id}`,
            { status: "Ready" },
            { headers: { token: Theme.user.accessToken } });
        getAllTable();
    }, [Theme.user.accessToken, getAllTable]);


    const memoizedTables = useMemo(() => {
        return tables.map((card) => (
            <div className={`table ${card?.status}`} key={card._id}>
                {/* <Button onClick={() => getTableId(card)}>Ready</Button> */}
                <div className="number">T {card.table?.tableNumber}</div>
                <div className="tname">{card.table?.name}</div>
                <div className="tname">{card.status}</div>
                {
                    card.item && card.item.map((item) => (
                        <div className="titem" key={item._id}>
                            <div className="qry">qry {item.quantity}</div>
                            <div className="name">{item.name}</div>
                            <div className="note"> {item.note}</div>
                            {/* modifier */}

                            <div className="modifier">
                                {item?.modifier && <span>{t('Modifier')}</span>}
                                {item?.modifier &&
                                    item.modifier.map((mod) => (
                                        <div
                                            className={"modifier__item "}
                                            key={mod._id}
                                        >
                                            <div className="price">
                                                <span>{mod.price}</span>
                                            </div>
                                            <div className="name">
                                                <span>{mod.name}</span>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            {/* modifier */}

                        </div>
                    ))
                }
            </div>
        ));
    }, [tables, getTableId]);

    return (
        <div className="Kitchen">
            <div className="Kitchen__right">
                <div className="kitchen ">
                    <div className="tablecart" onClick={() => setCartShow(!cartShow)} >
                        <img src="/table.png" alt="" />
                        <span className="cartN">{tables.length}</span>
                    </div>


                    <Modal
                        open={cartShow}
                        onClose={() => setCartShow(false)}
                        className={classes.modal}
                    >
                        <div className={classes.paper}>
                            <div className="kitchentap">
                                <h1>my table {table?.numberOf}</h1>
                                {memoizedTables}
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>

            <div className="tables1">

                <div className={'table1 ' + table.status}>
                    T {table?.tableNumber} {table?.name}
                </div>
            </div>
        </div>
    );
};



const Casher = () => {

    const Theme = useContext(ThemeContext);

    const [backupCard, setBackupCard] = useState([]);

    const [card, setCard] = useState([]);
    const [category, setCategory] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [cartShow, setCartShow] = useState(false);
    const [cusOpen, setCusOpen] = useState(false);
    const [total, setTotal] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [paid, setPaid] = useState(0);
    const [myTable, setMyTable] = useState([]);
    const [discountType, setDiscountType] = useState('0');
    const [discount, setDiscount] = useState(0);
    const [AcceptOpenTable, setAcceptOpenTable] = useState(false);
    const { t, i18n } = useTranslation();


    const classes = useStyles();
    const { id } = useParams();

    // function add to theme.Theme.cart
    const addCart = (item) => {
        const check = Theme.cart.find((i) => i._id === item._id);

        // play sound when add to cart 
        const audio = new Audio('/sound_beep-29.mp3');
        audio.play();

        if (check) {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: ++i.quantity } : i))

        } else {
            Theme.setCart([...Theme.cart, { ...item, quantity: 1, maxqrt: item.quantity }]);
        }
    }

    const updateCart = (item) => {
        Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: ++i.quantity } : i))
    }

    const downcart = (item) => {
        const check = Theme.cart.find((i) => i._id === item._id);
        if (check.quantity > 1) {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: --i.quantity } : i))
        }
    }


    const countUp = (item, num) => {
        const check = Theme.cart.find((i) => i._id === item._id);

        if (num == 'c') {
            // Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: i.quantity.toString().slice(0, -1) } : i)) and if quantity == 0 remove item from Theme.cart 
            // if (check.quantity.toString().length > 1) { Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString().slice(0, -1)) } : i)) } else { Theme.setCart(Theme.cart.filter((i) => i._id !== item._id)) }
            // qauntity make 0
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: 0 } : i))
        }
        else if (num == 'd') {
            // Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: i.quantity.toString().slice(0, -1) } : i)) and if quantity == 0 remove item from Theme.cart
            if (check.quantity.toString().length > 0) { Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString().slice(0, -1)) } : i)) }
        }
        else {
            Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: Number(i.quantity.toString() + num) } : i))
        }

    }


    useEffect(() => {
        getAllCat();
        getAllProduct();
        getAllCustomer();
    }, []);




    const getAllCat = async () => {
        let response = await allCategory();
        setCategory(response.data);
    }

    const getSubCat = async (e) => {
        let response = await allSubcatogery('category/' + e);
        setsubCategory(response.data);
    }



    const getAllProduct = async () => {
        let response = await allProduct();
        setCard(response.data.data);
        setBackupCard(response.data.data);
    }
    // filter by category
    const filterCat = async (id) => {
        let response = await allProduct();
        let result = response.data.data.filter((item) => item.category._id === id);
        setCard(result);
        getSubCat(id)
    }
    // filter by subcategory
    const filterSubCat = async (id) => {
        let response = await allProduct();
        let result = response.data.data.filter((item) => item.subcategory === id);
        setCard(result);
    }

    const getAllCustomer = async () => {
        let response = await allCustomers();
        setCustomer(response.data);
    }



    useEffect(() => {
        let total = Theme.cart.reduce((a, b) => a + (b.price * b.quantity), 0);
        let netTotal = Theme.cart.reduce((a, b) => a + (b.price * b.quantity) - (b.discount * b.quantity), 0);
        let paid = Theme.cart.reduce((a, b) => a + (b.price * b.quantity), 0);
        if (discountType === 'percent') {
            netTotal = netTotal - (netTotal * discount / 100);
            paid = paid - (paid * discount / 100);
        }
        else {
            netTotal = netTotal - discount;
            paid = paid - discount;
        }
        setDiscount(discount);
        setDiscountType(discountType);
        setTotal(total);
        setNetTotal(netTotal);
        setPaid(paid);
    }, [discount, discountType, Theme.cart])



    const addbill = async (e) => {
        e.preventDefault();
        // check if cart is empty
        if (Theme.cart.length === 0) {
            return
        }
        if (Theme.user.accessToken === undefined) {
            return
        }

        e.target.querySelector('button[type="submit"]').disabled = true;



        await axios.put(
            `/api/v1/tables/items/accept`,
            {
                status: "accept",
                // price: (netTotal + notifications[0].price), القيمة المضافة 14 % و12  خدمة
                price: (netTotal + notifications[0].price + (netTotal * 0.14) + (netTotal * 0.12)),
                id: notifications[0]._id,
            },
            { headers: { token: Theme.user.accessToken } }
        );
        // 

        const products = Theme.cart.map((item) => {
            return {
                product: item._id,
                name: item.name,
                category: item.category._id,
                quantity: item.quantity,
                price: item.price,
                purchase: item.purchase,
                tax: item.tax,
                note: item.note,
                modifier: item.modifier,
            }
        })

        let response = await axios.post(`/api/v1/tables/items`,
            {
                status: "Pending", item: Theme.cart,
                table: id
            },
            { headers: { token: Theme.user.accessToken } })

        if (response.status === 201 || response.status === 200) {
            toast.success('تم ارسال الطلب بنجاح');
        } else {
            toast.error('حدث خطأ ما');
        }


        // Loop through the items in the card array
        const updatedCard = await card.map((item) => {
            // Check if there are active modifiers in the item
            const hasActiveModifiers = item.modifier.some((mod) => mod.active);

            // If there are active modifiers, update the item price
            if (hasActiveModifiers) {
                const updatedItem = {
                    ...item,
                    price: item.price - item.modifier.filter((mod) => mod.active).reduce((total, mod) => total + mod.price, 0),
                    modifier: item.modifier.map((mod) => mod.active ? { ...mod, active: false } : mod),
                };
                return updatedItem;
            }
            // If there are no active modifiers, return the original item
            return item;
        });

        // Set the updated card in the state and empty the cart
        setCard(updatedCard);
        Theme.setCart([]);

        Theme.setCustomer({});
        getAllCustomer();

        const audio = new Audio('/removing.mp3');
        audio.play();
        e.target.querySelector('button[type="submit"]').disabled = false;

    }



    const sendReqAccept = async (e) => {
        toast.success('Successfully');

        if (Theme.user.accessToken === undefined) {
            return
        }
        await axios.post(`/api/v1/tables/items/accept`,
            {
                status: "Pending",
                table: id
            },
            { headers: { token: Theme.user.accessToken } });

    }


    const getTableBill = async (id) => {
        let response = await axios.get(`/api/v1/tables/items/table/${id}`);
        setMyTable(response.data.item);
        console.log(response.data);
    }
    useEffect(() => {
        getTableBill(id);
    }, [id])



    //  modifier [
    //     {
    //         "name": "fdc",
    //         "price": 0,
    //         "_id": "63fe528b1acdd87d4953ed8c"
    //     },
    //     {
    //         "name": "EDS",
    //         "price": 0,
    //         "_id": "63fe528b1acdd87d4953ed8d"
    //     },
    //     {
    //         "name": "WS",
    //         "price": 0,
    //         "_id": "63fe528b1acdd87d4953ed8e"
    //     }
    // ]






    const addCartModifier = async (item, modifier) => {
        const check = await Theme.cart.find((i) => i._id === item._id);
        const checkModifier = check ? await check.modifier.find((i) => i._id === modifier._id) : null;
        // get all price of modifier 
        const priceModifier = await item.modifier.filter((i) => i.active).reduce((total, mod) => total + mod.price, 0);

        const cartBP = await backupCard.find((i) => i._id === item._id);

        let Modifier = checkModifier;


        if (check) { // Item is already in cart

            if (modifier.active) { // Modifier is already applied
                if (modifier.price > 0) {
                    let price = item.price - modifier.price;
                    card.find((i) => i._id === item._id).price = price;
                }
                card.find((i) => i._id === item._id).modifier.find((m) => m._id === modifier._id).active = false;
                Modifier = { ...modifier, active: false };
            }
            else { // Modifier is not applied
                if (modifier.price > 0) {
                    let price = item.price + modifier.price;
                    card.find((i) => i._id === item._id).price = price;
                }
                card.find((i) => i._id === item._id).modifier.find((m) => m._id === modifier._id).active = true;
                Modifier = { ...modifier, active: true };
            }
            Theme.setCart(Theme.cart.map((i) => {
                if (i._id === item._id) {
                    return { ...i, price: item.price, modifier: [...i.modifier.filter((m) => m._id !== modifier._id), Modifier] };
                }
                else {
                    return i;
                }
            }));


        } else { // Item is not in cart
            if (modifier.price > 0) {
                item.price = item.price + modifier.price;
            }
            card.find((i) => i._id === item._id).modifier.find((m) => m._id === modifier._id).active = true;
            Theme.setCart([...Theme.cart, { ...item, price: item.price, quantity: 1, modifier: [{ ...modifier, active: true }] }]);
        }
    }







    const [notifications, setNotifications] = useState([]);
    const getNotif = async () => {
        let response = await axios.get(`/api/v1/tables/items/accept/${id}`);
        console.log(response);
        setNotifications(response.data);
    }
    useEffect(() => {
        getNotif();
    }, [])


    useEffect(() => {
        const socket = io();

        socket.on('connect', () => {
            console.log('Connected to WebSocket server');
        });

        socket.on('acceptOpenTable', (data) => {
            new Audio('/triangle_open_01-102876.mp3').play();
            console.log(`Received echo: ${data}`);
            getNotif();
        });

        return () => {
            socket.disconnect();
        };
    }, []);





    const handleAccept = async (id, status) => {
        try {
            await axios.put(
                `/api/v1/tables/items/accept`,
                {
                    status: status,
                    id: id,
                },
                { headers: { token: Theme.user.accessToken } }
            );
            setNotifications((prevNotifications) =>
                prevNotifications.filter((n) => n.table._id !== id)
            );
        } catch (error) {
            console.error(error);
        }
    };




    const [search, setSearch] = useState('');
    const [cardModel, setCardModel] = useState([]);
    const [cardModelShow, setCardModelShow] = useState(false);


    return (
        <div className="guset">
            <ToastContainer />


            <div className="cart" onClick={() => setCartShow(!cartShow)} >
                <img src="/cart.png" alt="" />
                <span className="cartN">{Theme.cart.reduce((total, item) => total + item.quantity, 0)}</span>
            </div>
            {cartShow &&
                <div className="casher__header">

                    <button className="casher__header__btn" onClick={() => setCartShow(!cartShow)}>
                        <div className="item">

                            <div className="invoice__total">
                                <FaCashRegister color="blue" />
                                <span>{t('Total')} </span>
                                <span>{total}</span>
                            </div>
                            <div className="total__qrt">
                                <span>{t('qrt')} </span>
                                <span>{Theme.cart.reduce((a, b) => a + (b.quantity), 0)}</span>
                            </div>
                            <div className="backarrow">
                                <FaArrowLeft /> {t('back')}
                            </div>
                            {/* <div className="invoice__custem">
                            <span>{t('Customer')} </span>
                            <span>{Theme.customer?.name}</span>
                        </div>
                        <div className="invoice__custem">
                            <span>{t('disc')} </span>
                            <span>{discountType === 'percent' ? discount + '%' : discount + '$'}</span>
                        </div> */}
                        </div>
                    </button>


                    <div className="casher__left">


                        <form className="invoice" onSubmit={(e) => addbill(e)}>
                            {/* remove cart */}
                            <button onClick={() => {
                                // Loop through the items in the card array
                                const updatedCard = card.map((item) => {
                                    // Check if there are active modifiers in the item
                                    const hasActiveModifiers = item.modifier.some((mod) => mod.active);

                                    // If there are active modifiers, update the item price
                                    if (hasActiveModifiers) {
                                        const updatedItem = {
                                            ...item,
                                            price: item.price - item.modifier.filter((mod) => mod.active).reduce((total, mod) => total + mod.price, 0),
                                            modifier: item.modifier.map((mod) => mod.active ? { ...mod, active: false } : mod),
                                        };
                                        return updatedItem;
                                    }
                                    // If there are no active modifiers, return the original item
                                    return item;
                                });

                                // Set the updated card in the state and empty the cart
                                setCard(updatedCard);
                                Theme.setCart([]);
                            }} className='btn-remove'><FaTrashAlt /> All</button>



                            {notifications.map((item, index) => (
                                item.status === 'accept' ? (
                                    <button className="invoice__pay" type="submit" key={index}>
                                        <span> <FaRegMoneyBillAlt /> {t('send')} </span>
                                        <span className="total">{total}</span>
                                        <span>{paid}</span>
                                    </button>
                                ) : (
                                    <div className="invoice__pay" key={index}>
                                        {t('please_open_table_first')}
                                    </div>
                                )
                            ))}

                            {/* 
                            <div className="cuscash">

                                <div className="customer">


                                    <div className="list">
                                        <div className="drop" onClick={() => { setCusOpen(!cusOpen) }}>
                                            <h3>   <input type="text" onChange={searchCustomer} placeholder={Theme.customer?.name ? Theme.customer?.name : t('Customer')} /> <FaAngleDown />  </h3>

                                            <div className="drop__content">
                                                {cusOpen && customer.map((item, index) => (
                                                    <div className="drop__item" key={index} onClick={() => selectCustomer(item)}>
                                                        <p>{item.name}</p>
                                                        <p className="number">{item.phone?.split(",").map(str => <div key={str}>{str}</div>)}</p>
                                                    </div>
                                                ))}
                                                {cusOpen && customer.length === 0 && <NavLink to="/admin/customer" className="customer__add"> <IoAddCircleOutline size={28} /> {t('Customer')}</NavLink>}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            */}


                        </form>





                        <div className="cartbxs">
                            {
                                Theme.cart.map((item, index) => {
                                    return (
                                        <div key={index} className='cartbx'>


                                            <div className="cx">
                                                <div className="info">
                                                    <div className="info__item">
                                                        <div className="quantity">qty {item.quantity}  </div>
                                                        <div className="price">price {item.price}  </div>
                                                        <div className="total">total {item.price * item.quantity}  </div>
                                                    </div>
                                                    <div className="name">
                                                        <div>
                                                            {/* <img src={item.image} width="22" height='22' />  */}
                                                            <span>{item.name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="qry">
                                                <button onClick={() => { updateCart(item) }} className='btn btn-success'>< FaPlus /> </button>
                                                {/* <input type="number" value={item.quantity} onClick={(e) => e.target.select()} onChange={(e) => Theme.setCart([...Theme.cart.slice(0, index), { ...item, quantity: e.target.value }, ...Theme.cart.slice(index + 1)])} /> */}
                                                <span>{item.quantity}</span>
                                                <button onClick={() => downcart(item)} className='btn btn-warning'> <FaMinus /> </button>
                                                <button onClick={() => {

                                                    let cardD = card.find((d) => d._id == item._id)
                                                    cardD.price = cardD.price - cardD.modifier.filter((mod) => mod.active).map((mod) => (mod.price))
                                                    cardD.modifier.filter((mod) => mod.active).map((mod) => (mod.active = false))
                                                    setCard([...card.slice(0, card.indexOf(cardD)), cardD, ...card.slice(card.indexOf(cardD) + 1)])

                                                    Theme.setCart([...Theme.cart.slice(0, index), ...Theme.cart.slice(index + 1)])

                                                }

                                                } className='btn btn-danger'><FaTrashAlt /></button>

                                                {/* 
                                                <div className="keyword-toutch">
                                                    <div>

                                                        <button onClick={() => countUp(item, 1)}>1</button>
                                                        <button onClick={() => countUp(item, 2)}>2</button>
                                                        <button onClick={() => countUp(item, 3)}>3</button>
                                                        <button onClick={() => countUp(item, 4)}>4</button>
                                                        <button onClick={() => countUp(item, 5)}>5</button>
                                                        <button onClick={() => countUp(item, 6)}>6</button>
                                                        <button onClick={() => countUp(item, 7)}>7</button>
                                                        <button onClick={() => countUp(item, 8)}>8</button>
                                                        <button onClick={() => countUp(item, 9)}>9</button>
                                                        <button onClick={() => countUp(item, 0)}>0</button>
                                                        <button onClick={() => countUp(item, 'd')}>back</button>
                                                        <button onClick={() => countUp(item, 'c')}>clear</button>

                                                    </div>
                                                </div>
                                                 */}

                                            </div>
                                            <input type="text" name="note" placeholder={t('note')} onChange={(e) => Theme.setCart([...Theme.cart.slice(0, index), { ...item, note: e.target.value }, ...Theme.cart.slice(index + 1)])} />


                                            {/* modifier */}

                                            <div className="modifier">
                                                {item.modifier.length > 0 && <span>{t('Modifier')}</span>}
                                                {item.modifier.length > 0 &&
                                                    item.modifier.map((mod) => (
                                                        <div
                                                            className={"modifier__item " + (
                                                                Theme.cart.find((cart) => cart._id === item._id).modifier.find((m) => m._id === mod._id) ? 'active' : ''
                                                            )}
                                                            key={mod._id}
                                                            onClick={() => addCartModifier(item, mod)}
                                                        >
                                                            <div className="price">
                                                                <span>{mod.price}</span>
                                                            </div>
                                                            <div className="name">
                                                                <span>{mod.name}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            {/* modifier */}

                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                </div>
            }
            <div className="casher__right">


                <main className="main-food">
                    <div className="container">
                        <div className="top">
                            <img className="header" src="/assets/header.png" />
                        </div>
                        <div className="content">
                            <div className="info">
                                <h2>المينو</h2>
                                <nav>

                                    {notifications.length > 0 ? (
                                        notifications.map((item) => (
                                            <div className="req" key={item._id}>
                                                <div className="tb">
                                                    <span className="number">T {item.table?.tableNumber}</span>
                                                    <span className="tname"> {item.table?.name}</span>
                                                    <div className="tname">حالة الطاولة {
                                                        item.status == 'Pending' ? ' طلب فتح الطاولة ' : item.status == 'accept' ? ' تم فتح الطاولة ' : item.status == 'check' ? ' طلب الشيكك ' : item.status == 'Captain' ? ' طلب الكابتن ' : item.status == 'done' ? ' تم تنفيذ الطلب ' : item.status == 'cancel' ? ' تم الغاء الطلب ' : ''
                                                    }</div>
                                                </div>
                                                <div className="tax">
                                                    <span className="tname"> القيمة المضافة 14%</span>
                                                    <span className="number"> = {(item.price * 0.14).toFixed(2)} </span>
                                                    <div className="tname">خدمة 12%  = {(item.price * 0.12).toFixed(2)}</div>
                                                </div>
                                                <div className="total">
                                                    <span className="tname">إجمالي الفاتورة</span>
                                                    <span className="number"> {item.price.toFixed(2)} </span>
                                                </div>
                                                {
                                                    item.status === 'check' && <div className="tname">
                                                        تم استلام طلبكم وجارى تنفيذه
                                                    </div>
                                                }
                                                {
                                                    item.status === 'Captain' && <div className="tname">
                                                        تم استلام طلبكم وجارى تنفيذه
                                                    </div>
                                                }
                                                {
                                                    item.status === 'accept' && <div className="tname">
                                                        تم قبول فتح الطاولة
                                                    </div>
                                                }
                                                {
                                                    item.status === 'Pending' && <div className="tname">
                                                        تم استلام طلبكم وجارى تنفيذه
                                                    </div>
                                                }
                                                {item.status === 'accept' &&
                                                    <button className="accept" onClick={() => handleAccept(item._id, 'check')}>
                                                        طلب الشيك
                                                    </button>
                                                }
                                                {item.status === 'accept' &&
                                                    <button className="accept" onClick={() => handleAccept(item._id, 'Captain')}>
                                                        طلب كابتن
                                                    </button>
                                                }
                                            </div>
                                        ))
                                    ) : (
                                        <button className="btn btn-success" onClick={sendReqAccept}>
                                            طلب فتح الطاولة
                                        </button>
                                    )}
                                </nav>
                            </div>




                            {/* <div className="grid">

                                {category.map((cat) => (
                                    <div className="item-holder" key={cat._id}>
                                        <p className="item" value={cat._id} onClick={() => filterCat(cat._id)} >
                                            <img src={cat.image} alt="" />
                                            <span className="label" >{cat.name}</span>

                                        </p>
                                    </div>
                                ))}

                            </div>

                            <div className="grid">
                                {subCategory.length > 0 && <h1>sub menu </h1>}
                                {subCategory.length > 0 && subCategory.map((sub) => (
                                    <div className="item-holder" key={sub._id}>
                                        <p className="item" value={sub._id} onClick={() => filterSubCat(sub._id)}>
                                            <img src={sub.image ? sub.image : sub.category.image} alt="" />
                                            <span className="label" >{sub.name}</span>
                                        </p>
                                    </div>
                                ))}
                            </div> */}

                        </div>
                    </div>
                </main>

                {/* 
                <div className="t">
                    {
                        myTable && myTable.map((item) => (
                            <div className="titem" key={item._id}>
                                <div className="qry">qry {item._id}</div>
                                <div className="name">{item.name}</div>
                                <div className="note"> {item.note}</div>
                            </div>
                        ))
                    }
                </div>


                {notifications.length > 0 ? (
                    notifications.map((item) => (
                        <div className="table" key={item._id}>
                            <div className="number">T {item.table?.tableNumber}</div>
                            <div className="tname">{item.table?.name}</div>
                            <div className="tname">{item.status}</div>
                        </div>
                    ))
                ) : (
                    <button className="btn btn-success" onClick={sendReqAccept}>
                        طلب فتح الطاولة
                    </button>
                )}



                <div className="catg">
                    {category.map((cat) => (
                        <p key={cat._id} value={cat._id} onClick={() => filterCat(cat._id)} >
                            <img src={cat.image} alt="" />
                            <span>{cat.name}</span>

                        </p>
                    ))}
                </div>
                <div className="catgs">
                    {subCategory.length > 0 && <p>sub menu </p>}
                    <div className="catg">
                        {subCategory.length > 0 && subCategory.map((sub) => (
                            <p key={sub._id} value={sub._id} onClick={() => filterSubCat(sub._id)}>
                                <img src={sub.image ? sub.image : sub.category.image} alt="" />
                                <span>{sub.name}</span>
                            </p>
                        ))}
                    </div>
                </div>
                 */}
                <section className="media max-w-[1100px] m-auto ">
                    <div className="info flex my-4 rounded-full justify-start items-center bg-slate-100 ">
                        <img src="/451.jpg" className=" rounded-3xl h-40 w-40" />
                        <div className="detail px-2">
                            <h2 className="  text-3xl text-yellow-600 font-bold ">دله العرب</h2>
                            <p className="text-gray-500 text-xl">كافية دلة العرب</p>
                        </div>
                    </div>
                    <div className="info flex justify-start items-center bg-slate-100 ">
                     <button className="bg-red-500 text-white p-2 rounded-3xl text-left">فئات قائمة الطعام</button>
                    </div>
                  
                </section>

                <div className=" top-1 shadow-2xl p-3 rounded-3xl flex flex-wrap  max-w-[1100px] m-auto mb-6">
                            {category.map((cat) => (
                                <a href={`#m${cat._id}`} className=" p-1 w-auto m-2 rounded-full  bg-red-100 hover:bg-red-400 block " key={cat._id}>
                                    <p className=" flex justify-start items-center" >
                                        <div className="p-1 shadow-lg bg-red-300 rounded-full relative -left-3 flex items-center justify-center "><IoFastFoodOutline size={24} /></div>
                                        <span className="label p-2" >{cat.name}</span>

                                    </p>
                                </a>
                            ))}
                        </div>


                <section className=" flex max-w-[1100px] m-auto ">

                    <div className=" w-[250px] rounded-xl  max-sm:hidden">

                        <div className=" top-1 shadow-2xl p-3 rounded-3xl ">
                            <p>فئات قائمة الطعام</p>
                            {category.map((cat) => (
                                <a href={`#m${cat._id}`} className=" p-1 w-full m-2 rounded-full  bg-red-100 hover:bg-red-400 block " key={cat._id}>
                                    <p className=" flex justify-start items-center" >
                                        <div className="p-1 shadow-lg bg-red-300 rounded-full relative -left-3 flex items-center justify-center "><IoFastFoodOutline size={24} /></div>
                                        <span className="label p-2" >{cat.name}</span>

                                    </p>
                                </a>
                            ))}
                        </div>
                    </div>



                    <div className="accordion-cashier flex-1 " >
                        <div className="search">
                            <input type="text" placeholder="بحث" onChange={
                                (e) => { setSearch(e.target.value) }
                            }
                                className="border-2 border-gray-300 rounded-lg p-1 w-full text-right outline-none focus:border-red-500"
                            />
                        </div>

                        {card
                            .filter((cardD) => cardD.name.includes(search))
                            // filter unqie category 
                            .filter((cardD, index, self) => self.findIndex((t) => t.category._id === cardD.category._id) === index)
                            .map((cat) => (
                                <div className={`Accordion m${cat._id} ${window.innerWidth <= 768 ? 'show' : ''}`} key={cat._id} >
                                    <div className="AccordionSummary flex items-center "
                                        onClick={
                                            (event) => {
                                                document.getElementsByClassName(`m${cat._id}`)[0].classList.toggle('show');
                                            }
                                        }
                                    >
                                        <ExpandMoreIcon />
                                        <p> {cat.category.name} </p>
                                    </div>
                                    <div className="AccordionDetails ">
                                        <section className="cards-cashier" id={`m${cat.category._id}`}>
                                            {
                                                card
                                                    .filter((cardD) => cardD.category._id === cat.category._id)
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .length > 0 ?
                                                    card.filter((cardD) => cardD.category._id === cat.category._id)

                                                        .filter((cardD) => cardD.name.toLowerCase().includes(search))
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((cardD, index) => (

                                                            <div className={`card ${Theme.cart.find((cart) => cart._id === cardD._id) ? 'active' : ''
                                                                }`} key={cardD._id}
                                                                onClick={() => {
                                                                    setCardModel(cardD);
                                                                    setCardModelShow(true);
                                                                }}
                                                            >
                                                                {/* <img className="card__image" src={cardD.image} /> */}
                                                                <span className="card__price">{
                                                                    // discount before price and after price Discount
                                                                    cardD.discount > 0 ? (
                                                                        <div>
                                                                            <span style={
                                                                                { textDecoration: 'line-through', color: 'red' }
                                                                            }>{cardD.price} </span>
                                                                            <span className="card__price--after">{(cardD.price - (cardD.price * cardD.discount / 100)).toFixed(2)}</span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="price">

                                                                            {cardD.priceBeforeDiscount > 0 && <span style={{ textDecoration: 'line-through', color: 'red', backgroundColor: 'black', fontWeight: 'bolder' }}>{cardD.priceBeforeDiscount} </span>}

                                                                            <span className="card__price--after">{cardD.price}</span>

                                                                        </div>

                                                                    )
                                                                }</span>
                                                                <span className="card__name">{cardD.name}</span>
                                                                {/* <p className="qry">qry; {cardD.quantity}</p> */}
                                                                <div className="modifier">
                                                                    {cardD.modifier.length > 0 && <span>{t('السعر حسب الإختيار')}</span>}
                                                                    {cardD.modifier.length > 0 &&
                                                                        cardD.modifier.map((mod) => (
                                                                            <div
                                                                                className={"modifier__item " + (mod.active ? "active" : "")}
                                                                                key={mod._id}
                                                                                onClick={() => addCartModifier(cardD, mod)}
                                                                            >
                                                                                <div className="price">
                                                                                    <span>{mod.price} جنيه</span>
                                                                                </div>
                                                                                <div className="name">
                                                                                    <span>{mod.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>


                                                                <div className="card__btn_qry">
                                                                    <button onClick={() => addCart(cardD)} className="btn-plus"><FaPlus /></button>
                                                                    <div className="qty"> {Theme.cart.find((item) => item._id === cardD._id) ? Theme.cart.find((item) => item._id === cardD._id).quantity : 0}</div>
                                                                    <button onClick={() => Theme.setCart(Theme.cart.map((i) => i._id === cardD._id ? { ...i, quantity: --i.quantity } : i).filter((i) => i.quantity > 0)
                                                                        // get modifier if active and  remove price and active modifier from price card and active modifier
                                                                        , card.find((i) => i._id === cardD._id).modifier.filter((i) => i.active).map((i) => cardD.price -= i.price, cardD.modifier.map((i) => i.active = false))

                                                                    )} className="btn-minus"><FaMinus /></button>
                                                                </div>
                                                            </div>
                                                        ))  // end map
                                                    : <h1>{t('no product')}</h1>

                                            }
                                        </section>

                                    </div>
                                </div>
                            ))}


                    </div>
                    

                </section>


                {
                    cardModelShow == true ? 
                    <section className=" cardmodel ">
                        <div className="headermodal ">
                            <div className="flex items-center">
                                <button className="btn-close close  " onClick={() => setCardModelShow(false)}><FaTimes /></button>
                                <h1 className="title">{cardModel.name}</h1>
                            </div>
                        </div>

                        <div className={`cardmo flex  ${Theme.cart.find((cart) => cart._id === cardModel._id) ? 'active' : ''
                            }`} key={cardModel._id}
                            onClick={() => {
                                setCardModel(cardModel);
                                setCardModelShow(true);
                            }}
                        >
                            <img className="card__image" src={cardModel.image} />
                            <div className="">

                                <span className="card__price">{
                                    // discount before price and after price Discount
                                    cardModel.discount > 0 ? (
                                        <div>
                                            <span style={
                                                { textDecoration: 'line-through', color: 'red' }
                                            }>{cardModel.price} </span>
                                            <span className="card__price--after">{(cardModel.price - (cardModel.price * cardModel.discount / 100)).toFixed(2)}</span>
                                        </div>
                                    ) : (
                                        <div className="price">

                                            {cardModel.priceBeforeDiscount > 0 && <span style={{ textDecoration: 'line-through', color: 'red', fontWeight: 'bolder' }}>{cardModel.priceBeforeDiscount} جنيه </span>}

                                            <span className="card__price--after">{cardModel.price} جنيه</span>

                                        </div>

                                    )
                                }</span>
                                <span className="card__name">{cardModel.name}</span>
                                {/* <p className="qry">qry; {cardModel.quantity}</p> */}

                                <div className="card__btn_qry">
                                    <button onClick={() => addCart(cardModel)} className="btn-plus"><FaPlus /></button>
                                    <div className="qty"> {Theme.cart.find((item) => item._id === cardModel._id) ? Theme.cart.find((item) => item._id === cardModel._id).quantity : 0}</div>
                                    <button onClick={() => Theme.setCart(Theme.cart.map((i) => i._id === cardModel._id ? { ...i, quantity: --i.quantity } : i).filter((i) => i.quantity > 0)
                                        // get modifier if active and  remove price and active modifier from price card and active modifier
                                        , card.find((i) => i._id === cardModel._id).modifier.filter((i) => i.active).map((i) => cardModel.price -= i.price, cardModel.modifier.map((i) => i.active = false))

                                    )} className="btn-minus"><FaMinus /></button>
                                </div>

                                <div className="modifier">
                                    {cardModel?.modifier?.length > 0 && <span>{t('السعر حسب الإختيار')}</span>}
                                    {cardModel?.modifier?.length > 0 &&
                                        cardModel?.modifier.map((mod) => (
                                            <div
                                                className={"modifier__item " + (mod.active ? "active" : "")}
                                                key={mod._id}
                                                onClick={() => addCartModifier(cardModel, mod)}
                                            >
                                                <div className="price">
                                                    <span>{mod.price} جنيه</span>
                                                </div>
                                                <div className="name">
                                                    <span>{mod.name}</span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>


                        </div>
                                 {
                                     Theme.cart.find((item) => item._id === cardModel._id) && 
                                     <div onClick={() => {      
                                        setCardModelShow(false);
                                    }}
                                     className="total_item_cart cursor-pointer bg-lime-600 p-2 my-3 shadow m-auto rounded-full w-100"  
                                       >
                                        <span className=" text-white">تم الاضافة و السعر</span>
                                        <span className=" bg-slate-100 rounded-full p-2 px-1">{ 
                                        // Theme.cart.find((item) => item._id === cardModel._id) ? Theme.cart.find((item) => item._id === cardModel._id).quantity : 0 * cardModel.price 
                                        Theme.cart.find((item) => item._id === cardModel._id) ? Theme.cart.find((item) => item._id === cardModel._id).quantity * cardModel.price : 0 
                                        
                                    } جنيه</span>
                                    </div>
    
                                 }
                    </section>
                    : ''
                }
            </div>
        </div >
    );
}


export default () => {

    const Theme = useContext(ThemeContext);
    const [showSignInOut, setShowSignInOut] = useState(true);

    return (
        <main>
            {!Theme.user && <Sing_in_out />}
            {Theme.user && (
                <div>
                    <Header />
                    {/* <Kitchen /> */}
                    <Casher />
                    <footer>
                        <a href="https://softwep.com/">https://softwep.com</a>
                    </footer>
                </div>
            )}
        </main>
    )
}

