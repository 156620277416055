import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { useState, createContext, useEffect } from "react";
import Home from "./pages/home/Home";
import Singup from "./pages/sing/Singup";
import Singin from "./pages/sing/Singin";
import Sing_in_out from "./pages/sing/Sing_in_up";
import Dashboard from './pages/admin/dashboard';
import Category from "./pages/admin/Category/Category";
import EditCategory from "./pages/admin/Category/EditCategory";

import SubCategory  from "./pages/admin/subCategory";
import Product from "./pages/admin/product";
import Site from "./pages/admin/site";
import Printer  from "./pages/admin/printer";
import Log from "./pages/admin/Log";


import User from "./pages/admin/User";
import Bill from './pages/admin/Bill';
import Table from "./pages/admin/Table";
import Customer from "./pages/admin/Customer";
import Cart from "./pages/cart/Cart";
import Single from "./pages/singee/Single";
import Cashier from "./pages/cashier/Cashier";
import Dining from "./pages/dining/Dining";
import Kitchen from "./pages/kitchen/kitchen";
import GuestID from "./pages/guest/Guest";
import './App.css';


export const ThemeContext = createContext({});
function App() {
  const [show, setShow] = useState(false);
  const [cart, setCart] = useState([]); 
  const [customer,setCustomer] = useState([]);

  // const [user, setUser] = useState( localStorage.getItem('user') );
  // make user state and get user from local storage and if not found set it to null
  const [user, setUser] = useState( localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null );

  // check user if change 
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);



  return (
    <ThemeContext.Provider value={{
      show, setShow,
      cart, setCart,
      customer,setCustomer,
      user, setUser
    }}>
      <div className="App">
        <AppUrl />
      </div>
    </ThemeContext.Provider>
  );
}

const AppUrl = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/singup" element={<Singup />} />
        <Route path="/singin" element={<Singin />} />
        <Route path="/sing_in_out" element={<Sing_in_out />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/category" element={<Category />} />
        <Route path="/admin/category/edit/:id" element={<EditCategory />} />

        <Route path="/admin/subcategory" element={<SubCategory />} />
        <Route path="/admin/product" element={<Product />} />
        <Route path="/admin/user" element={<User />} />
        <Route path="/admin/printer" element={<Printer />} />
        <Route path="/product/:idx" element={<Single />} />
        <Route path="/admin/bill" element={<Bill />} />
        <Route path="/admin/table" element={<Table />} />
        <Route path="/admin/Customer" element={<Customer />} />
        <Route path="/admin/site" element={<Site />} />
        <Route path="/admin/log" element={<Log />} />
        <Route path="/cashier" element={<Cashier />} />
        <Route path="/dining" element={<Dining />} />
        <Route path="/kitchen" element={<Kitchen />} />
        <Route path="/guest/:id" element={<GuestID />} />
        {/* <Route path="search/:name" element={<Search />} />
        <Route path="cat/:name" element={<Cat />} />
        <Route path="cart/" element={<Cart />} /> */}
      </Routes>
    </BrowserRouter>
  );
}


export default App;
