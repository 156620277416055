import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


 

// the translationsname	phone	email	address	notes	edit	delete
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ar: {
    translation: {
        "dashboard": "لوحة القيادة",
        "bill": "الفواتير",
        "Customer": "العملاء",
        "cashier": "كاشير",
        "Product": "المنتج",
        "Category": "الفئة",
        "Subcategory": "الفئة الفرعية",
        "Users": "المستخدمين",
        "Price": "السعر",
        "Quantity": "الكمية",
        "Total": "المجموع",
        "action": "العمليات",
        "Add Product": "اضافة منتج",
        "Add": "اضافة",
        "Cash": "كاش",
        "Credit": "ائتمان",
        "Bank": "بنك",
        "takeaway": "التيك آواي",
        "delivery": "توصيل",
        "dining": "طاولات",
        "Cancel": "الغاء",
        "invoice" : "فاتورة",
        "Pay" : "دفع",
        "Add Bill": "اضافة فاتورة",
        "Add Customer": "اضافة عميل",
        "Add Cashier": "اضافة كاشير",
        "Add Category": "اضافة فئة",
        "Add Product": "اضافة منتج",
        "overview of your sales, and purchases and profit": "نظرة عامة على مبيعاتك ومشترياتك وربحك",
        "from": "من",
        "to": "الى",
        "totalAmount": "المبلغ الكلي",
        "totalProfit": "الربح الكلي",
        "name": "الاسم",
        "total Sales": "مجموع المبيعات",
        "Total Profite": "مجموع الربح",
        "Total Amount": "مجموع المبلغ",
        "total sell": "مجموع المبيعات",
        "Search": "بحث",
        "name": "الاسم",
        "Name": "الاسم",
        "phone": "الهاتف",
        "email": "البريد الالكتروني", 
        "address": "العنوان",
        "notes": "ملاحظات",
        "edit": "تعديل",
        "delete": "حذف",
        "Password": "كلمة المرور",
        "Confirm Password": "تاكيد كلمة المرور",
        
    }
  },
  en: {
    translation: {
        "dashboard": "dashboard",
        "bill": "bill",
        "customer": "customer",
        "cashier": "cashier",
        "Product": "Product",
        "Price": "Price",
        "Quantity": "Quantity",
        "Total": "Total",
        "action": "action",
        "Add Product": "Add Product",
        "Add": "Add",
        "Cancel": "Cancel",
        "Add Bill": "Add Bill",
        "Add Customer": "Add Customer",
        "Add Cashier": "Add Cashier",
        "Add Category": "Add Category",
        "Add Product": "Add Product",
    }
  }
};

i18n
.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ar", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

  export default i18n;