import Header from '../../header/Header';
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";

import './product.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allSite, addSite, deleteSite, editSite } from '../../api/Site';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';


import React, { useState, useEffect } from 'react';


function List() {

  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);


  const [site, setsite] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);




  useEffect(() => {
    getAllSubCat();
    getallSite();
  }, []);

  const deletePro = async (id) => {
    await deleteSite(id, Theme.user.accessToken);
    getAllSubCat();
    getallSite();
  }
  const getallSite = async () => {
    let response = await allSite('default');
    setCard(response.data);
    console.log('response');
  }

  const getAllSubCat = async () => {
    let response = await allSite();
    setsite(response.data);
    setCardUpdate(response.data);
  }

  console.log(Theme.user.accessToken);

  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    console.log(e.target);
    let response = await editSite('default', formData, Theme.user.accessToken);
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllSubCat();
      getallSite();  // call getallSite to update the card state variable
      setShow(false);
    } else {
      toast.error('Something went wrong');
    }
  }


  return (
    <div className='dashboard' >
      <Menu />
      <div className='body'>
        <ToastContainer />
        <h1>site bill </h1>

        <div className='add' onClick={() => { setShow(!show) }}><IoAddCircleOutline size={28} /> add info bill </div>
        <div className="form">
          {
            show && ReactDOM.createPortal(<div id="form__add">
              <div className="backdrop" onClick={() => { setShow(!show) }} > </div>

              <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
                <input type="text" name='brand' value='default' hidden />

                {/* currency option  */}
                <div className="form-group">
                  <label htmlFor="currency">currency</label>
                  <select name="currency" id="currency" value={cardUpdate.currency} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, currency: e.target.value })
                  } >
                    <option value="SAR">SAR</option> 
                    <option value="EGP">EGP</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                  } />
                </div>
                <div className="form-group">
                  <label htmlFor="address">address</label>
                  <input type="text" name="address" id="address" placeholder='address' value={cardUpdate.address} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, address: e.target.value })
                  } />
                </div>

                <div className="form-group">
                  <label htmlFor="phone">phone</label>
                  <input type="text" name="phone" id="phone" placeholder='phone' value={cardUpdate.phone} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, phone: e.target.value })
                  } />
                </div>
                {/* vat number */}
                <div className="form-group">
                  <label htmlFor="vat">vat code</label>
                  <input type="text" name="vat" id="vat" placeholder='vat' value={cardUpdate.vat} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, vat: e.target.value })
                  } />
                </div>
                {/* tax number */}
                <div className="form-group">
                  <label htmlFor="tax">tax %</label>  
                  <input type="text" name="tax" id="tax" placeholder='tax' value={cardUpdate.tax} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, tax: e.target.value })
                  } />
                </div>
                <div className="form-group">
                  <label htmlFor="email">email</label>
                  <input type="text" name="email" id="email" placeholder='email' value={cardUpdate.email} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, email: e.target.value })
                  } />
                </div>


                <div className="form-group">
                  <label htmlFor="image">image</label>
                  <div id="im">
                    <img src={
                      cardUpdate.image ? cardUpdate.image : '/upload.png'
                    } width='222' />
                    <input type="file" name="image" id="image" placeholder='image' onChange={
                      (e) => setCardUpdate({ ...cardUpdate, image: URL.createObjectURL(e.target.files[0]) })
                    } />
                  </div>
                </div>
                <button type="submit">{cardUpdate._id ? 'Update' : 'Add'}</button>


              </form>
            </div>, document.getElementById('modal'))
          }

        </div>

        <section className="cards">
          {
            showDel &&
            <div className="del">
              <div className="body">
                <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
                <div className="del__card">
                  <p>Are you sure you want to delete this Category?</p>
                  <h2>{del.name}</h2>
                  <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
                </div>
              </div>
            </div>
          }

          {
            card && <div style={
              { fontSize: '50px', fontWeight: '900', color: 'black', textAlign: 'center' }
            }>
              <p>name: {card.name}</p>
              <p>address: {card.address}</p>
              <p>phone: {card.phone}</p>
              <p>email: {card.email}</p>
              <p>currency: {card.currency}</p>
              <p>vat: {card.vat}</p>
              <p>tax %: {card.tax}</p>
              {/* <p>image: {card.logo}</p> */}
              <div className="img">
                <img src={card.logo} alt='upload' />
                </div>
            </div>
          }
        </section>
      </div>
    </div>
  );
}


function site() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default site;