import Header from '../../header/Header';
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { IoAddCircleOutline } from "react-icons/io5";

import './product.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allCategory, addCategory, deleteCategory, editCategory } from '../../api/Category';
import { allSubcatogery, addSubcatogery, deleteSubcatogery, editSubcatogery } from '../../api/SubCategory';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';


import React, { useState, useEffect } from 'react';


function List() {

  const Theme = useContext(ThemeContext);

  const [card, setCard] = useState([]);
  const [cardUpdate, setCardUpdate] = useState([]);
  
  const [category, setCategory] = useState([]);

  const [subCategory, setsubCategory] = useState([]);
  const [show, setShow] = useState(false);

  const [del, setDel] = useState([]);
  const [showDel, setShowDel] = useState(false);




  useEffect(() => {
    getAllCat();
    getAllSubCat();
    getallSubcatogery();
  }, []);

  const getAllCat = async () => {
    let response = await allCategory();
    setCategory(response.data);
  }
  const deletePro = async (id) => {
    await deleteSubcatogery(id, Theme.user.accessToken);
    getAllSubCat();
    getallSubcatogery(); 
  }
  const getallSubcatogery = async () => {
    let response = await allSubcatogery();
    setCard(response.data);
    console.log(response.data, 'response');
  }

  const getAllSubCat = async () => {
    let response = await allSubcatogery();
    setsubCategory(response.data);
  }

  console.log(Theme.user.accessToken);

  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let response = cardUpdate._id ? await editSubcatogery(cardUpdate._id, formData, Theme.user.accessToken) : await addSubcatogery(formData, Theme.user.accessToken);
    if (response.status === 201 || response.status === 200) {
      toast.success('Successfully');
      getAllSubCat();
      getallSubcatogery();  // call getallSubcatogery to update the card state variable
      setShow(false);
    } else {
      toast.error('Something went wrong');
    }
  }
  

  return (
    <div className='dashboard' >
      <Menu />
      <div className='body'>
        <ToastContainer />
        <h1>supCatgory </h1>

        <div className='add' onClick={() => { setShow(!show); setCardUpdate([]) }}><IoAddCircleOutline size={28} /> add Catgory </div>
        <div className="form">
          {
            show && ReactDOM.createPortal(<div id="form__add">
              <div className="backdrop" onClick={() => { setShow(!show); setCardUpdate([]) }} > </div>

              <form onSubmit={addProduct} method="post" encType='multipart/form-data'>
                <input type="text" name='_id' value={cardUpdate._id} hidden />
                <button type="submit">{cardUpdate._id ? 'Update' : 'Add'}</button>
                <div className="form__group">
                  <label htmlFor="category">Category</label>
                  <select name="category" id="category" defaultValue={cardUpdate.category}>
                    {
                      category.map((item, index) => {
                        return <option key={index} value={item._id}>{item.name}</option>
                      })
                    }
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name">name</label>
                  <input type="text" name="name" id="name" placeholder='name' value={cardUpdate.name} onChange={
                    (e) => setCardUpdate({ ...cardUpdate, name: e.target.value })
                  } />
                </div>
                <div className="imm">
                  <div id="im">
                    <img src={
                      cardUpdate.image ? cardUpdate.image : '/upload.png'
                    } alt='upload' />
                    <input type="file" name="image" id="image" placeholder='image' onChange={
                      (e) => setCardUpdate({ ...cardUpdate, image: URL.createObjectURL(e.target.files[0]) })
                    } />
                  </div>
                </div>


              </form>
            </div>, document.getElementById('modal'))
          }

        </div>

        <section className="cards">
          {
            showDel &&
            <div className="del">
              <div className="body">
                <div className="backdrop" onClick={() => { setShowDel(!showDel); setDel([]) }} > </div>
                <div className="del__card">
                  <p>Are you sure you want to delete this Category?</p>
                  <h2>{del.name}</h2>
                  <button onClick={() => { deletePro(del._id); setShowDel(false) }}> &#9832; Confirm the deletion</button>
                </div>
              </div>
            </div>
          }

          <table>
            <thead>
              <tr>
                <th>supCatgory</th>
                <th>image</th>
                <th>category</th>
                <th>action</th>
              </tr>
            </thead>
            <tbody>
              {
                card.map((item, index) => {
                  return <tr key={index}>
                    <td>{item.name}</td>
                    <td><img src={item.image
                    } alt="card" className="card__img" /></td>
                    <td>{item.category.name}</td>
                    <td>
                      <button onClick={() => { setCardUpdate(item); setShow(true) }}> &#10000; Edit</button>
                      <button onClick={() => { setDel(item); setShowDel(true) }}> &#9832; Delete</button>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
}


function subCategory() {
  return (
    <div>
      <Header />
      <div className="form admin-Product">

        <List />
      </div>
    </div>
  );
}


export default subCategory;