import Header from "../../header/Header"
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaFileInvoiceDollar, FaRegCreditCard, FaLuggageCart, FaSearchDollar, FaDonate, FaMoneyBillWave } from "react-icons/fa";

import { allProduct } from '../../api/Product';
import { allBills, addBills, deleteBills, editBills } from '../../api/Bill';
import { allCustomers, addCustomers, deleteCustomers, editCustomers } from '../../api/Customers';
import Invoice from '../../Components/Invoice';

import './Bill.scss'
import { ThemeContext } from '../../App';


import axios from "axios";
import {
    List,
    ListItem,
    ListItemText,
    Button,
    Modal,
    TextField,
    makeStyles
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        maxHeight: '80vh',
    },
}));











// function PaymentMethodTable({ data }) {
//     const [tableData, setTableData] = useState({});
//     const [paymentMethods, setPaymentMethods] = useState([]);

//     useEffect(() => {
//         // Group data by date and paymentMethod
//         let tableData = {};
//         let paymentMethods = [];
//         data.forEach(item => {
//             let date = new Date(item.createdAt).toISOString().slice(0, 10);
//             let paymentMethod = item.paymentMethod;
//             if (!tableData[date]) {
//                 tableData[date] = {};
//             }
//             if (!tableData[date][paymentMethod]) {
//                 tableData[date][paymentMethod] = {
//                     total: 0
//                 };
//                 if (!paymentMethods.includes(paymentMethod)) {
//                     paymentMethods.push(paymentMethod);
//                 }
//             }
//             tableData[date][paymentMethod].total += item.total;
//         });
//         setTableData(tableData);
//         setPaymentMethods(paymentMethods);
//     }, [data]);

//     return (
//         <table>
//             <thead>
//                 <tr>
//                     <th>Date</th>
//                     {paymentMethods.map(method => (
//                         <th key={method}>{method} Total</th>
//                     ))}
//                 </tr>
//             </thead>
//             <tbody>
//                 {Object.keys(tableData).map(date => (
//                     <tr key={date}>
//                         <td>{date}</td>
//                         {paymentMethods.map(method => (
//                             <td key={date + method}>
//                                 {tableData[date][method] ? tableData[date][method].total : 0}
//                             </td>
//                         ))}
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// }

function PaymentMethodTable({ data }) {
    const [tableData, setTableData] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        // Group data by date and paymentMethod
        let tableData = {};
        let paymentMethods = [];
        data.forEach(item => {
            let date = new Date(item.createdAt).toISOString().slice(0, 10);
            item.payment.forEach(payment => {
                let paymentMethod = payment.method;
                if (!tableData[date]) {
                    tableData[date] = {};
                }
                if (!tableData[date][paymentMethod]) {
                    tableData[date][paymentMethod] = {
                        total: 0
                    };
                    if (!paymentMethods.includes(paymentMethod)) {
                        paymentMethods.push(paymentMethod);
                    }
                }
                tableData[date][paymentMethod].total += payment.amount;
            });
        });
        setTableData(tableData);
        setPaymentMethods(paymentMethods);
    }, [data]);

    return (
        <table>
            <thead>
                <tr>
                    <th>Date</th>
                    {paymentMethods.map(method => (
                        <th key={method}>{method} Total</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(tableData).map(date => (
                    <tr key={date}>
                        <td>{date}</td>
                        {paymentMethods.map(method => (
                            <td key={date + method}>
                                {tableData[date][method] ? tableData[date][method].total : 0}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}





function DarkVariantExample() {
    const Theme = useContext(ThemeContext);


    const [billId, setBillId] = useState('');
    const [bill, setBill] = useState([]);
    const [billdetails, setBilldetails] = useState([]);
    const [show, setShow] = useState(false);

    const [mark, setMark] = useState({});
    const [markName, setMarkName] = useState('');

    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const [newVoidBill, setNewVoidBill] = useState({});


    const [t, i18n] = useTranslation();


    
    useEffect(() => {
        getAllBill();
    }, []);



    const getAllBill = async () => {
        let response = await allBills();
        setBill(response.data);
        setMark(response.data);
    }

    // get bill details
    const getBillDetails = async (item) => {
        setBilldetails(item);
    }


    const getTopProducts = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        let { start, end } = data;
        start = start || new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().slice(0, 10);
        end = end || new Date().toISOString().slice(0, 10);
        const response = await allBills(`${start}/${end}`);
        if (response.status === 200) {
            setBill(response.data);
            setMark(response.data);
        }
    };


    const OpenModal = (id) => {
        setModalOpen(true);
        setBillId(id)
    };

    const CloseModal = () => {
        setModalOpen(false);
    };

    const Change = e => {
        setNewVoidBill({ ...newVoidBill, [e.target.name]: e.target.value });
    };

    const Submit = e => {
        e.preventDefault();
        setNewVoidBill({ ...newVoidBill, bill: billId });
        axios.post("/api/v1/bills/void/" + billId, newVoidBill, { headers: { token: Theme.user.accessToken } }).then(res => {
            console.log(res.data.message);
            setModalOpen(false);
            getAllBill();
        });
    };


    return (
        <div className="casher">


            <Modal
                open={show}
                onClose={() => setShow(false)}
                className={classes.modal}
            >
                <div className={classes.paper}>
                    <Invoice item={billdetails} />
                </div>
            </Modal>

            <Modal
                open={modalOpen}
                onClose={CloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>
                    <form onSubmit={Submit}>
                        <TextField
                            name="reason"
                            label="Reason"
                            onChange={Change}
                            fullWidth
                        />
                        <TextField
                            name="note"
                            label="Note"
                            onChange={Change}
                            fullWidth
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            className={classes.button}
                        >
                            Create Void Reset
                        </Button>
                    </form>
                </div>
            </Modal>

            <div className="casher__right">
                <h1><FaFileInvoiceDollar /> {t('bills')} </h1>
                <p> {t('overview of your sales, and purchases and profit')} </p>
                <div className="bar" >
                    <form id="data-sel" onSubmit={getTopProducts} >
                        <label for="start">{t('from')}:</label>
                        <input type="date" id="start" name="start" onChange={getTopProducts} />

                        <label for="start">{t('to')}:</label>
                        <input type="date" id="end" name="end" />
                        <button type='supmit'> <FaSearchDollar size={28} /></button>
                    </form>
                </div>
                <div className="mark">
                    <div className="box">
                        <img src="/delivery.png" alt="" height={100} />
                        <Button variant="contained" color="primary" onClick={() => { setBill(mark.filter(a => a.mark == 'delivery')); setMarkName('delivery') }}>delivery</Button>
                    </div>
                    <div className="box">
                        <img src="/takeaway.jpg" alt="" height={100} />
                        <Button variant="contained" color="primary" onClick={() => { setBill(mark.filter(a => a.mark == 'takeaway')); setMarkName('takeaway') }}>takeaway</Button>
                    </div>
                    <div className="box">
                        <img src="/allfood.jpg" alt="" height={106} />
                        <Button variant="contained" color="primary" onClick={() => { setBill(mark); setMarkName('all') }}>all</Button>
                    </div>
                </div>
                {markName}
                <div className="data">
                    <div className="data-item credit">
                        <h1> <FaMoneyBillWave /> {bill.filter(a => a.status !== 'Void').reduce((a, b) => a + b.paid, 0).toFixed(2)} </h1>
                        <p> {t('Total all   ')} </p>
                    </div>
                    <div className="data-item Sales">
                        <h1> <FaLuggageCart /> {bill.filter(a => a.payment.some(p => p.method === 'cash') && a.status !== 'Void').reduce((a, b) => a + b.paid, 0).toFixed(2)} </h1>
                        <p> {t('total Cash')} </p>
                    </div>
                    <div className="data-item Profite">
                        <h1> <FaDonate /> {bill.filter(a => a.payment.some(p => p.method === 'Bank') && a.status !== 'Void').reduce((a, b) => a + b.paid, 0).toFixed(2)} </h1>
                        <p> {t('total Bank')} </p>
                    </div>
                    <div className="data-item Profite">
                        <h1> <FaDonate /> {bill.filter(a => a.status === 'Void').reduce((a, b) => a + b.paid, 0).toFixed(2)} </h1>
                        <h6> count Void: {bill.filter(a => a.status === 'Void').length} </h6>
                    </div>
                </div>


                <PaymentMethodTable data={bill} />
                <div className="bills">
                    <table>
                        <tr>
                            <th>data</th>
                            <th>Customer</th>
                            <th>Total</th>
                            <th>Payment Method</th>
                            <th>Mark</th>
                            <th>Status</th>
                            <th>View</th>
                            <th>void</th>
                        </tr>
                        {
                            bill.map((item, index) => {
                                return (
                                    <tr key={item._id}  >
                                        <td>{item.createdAt.slice(0, 10)}</td>
                                        <td>{item.customer?.name}</td>
                                        <td>{item.total}</td>
                                        <td>{item.payment.map((item, index) => {
                                            return (
                                                item.amount > 0 &&
                                                <span className="payment" key={index}>
                                                    <span>{item.method} </span>
                                                    <span>{item.amount} </span>
                                                </span>
                                            )
                                        })}</td>
                                        <td><span className={item.status}>{item.mark}</span></td>
                                        <td><span className={item.status}>{item.status}</span></td>
                                        <td>

                                            <Button variant="contained" color="primary" onClick={
                                                () => (getBillDetails(item), setShow(true))
                                            }>show </Button>
                                        </td>
                                        <td>
                                            <Button variant="contained" color="secondary" onClick={() => OpenModal(item._id)} >Void Reset</Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </table>






                </div>


            </div>
        </div>
    );
}




export default () => {

    return (
        <main>
            <Header />
            <DarkVariantExample />
        </main>
    )
}