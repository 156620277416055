import Header from "../../header/Header"
import { useState, useEffect, useContext } from "react";
import { allProduct } from '../../api/Product';
import { NavLink } from 'react-router-dom';
import { allCategory } from '../../api/Category';
import { allSubcatogery } from '../../api/SubCategory';
import './home.scss'
import { ThemeContext } from '../../App';
function DarkVariantExample() {
  const Theme = useContext(ThemeContext);


  const [card, setCard] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setsubCategory] = useState([]);



  // function add to theme.Theme.cart
  const addCart = (item) => {
    const check = Theme.cart.find((i) => i._id === item._id);

    // play sound when add to cart 
    const audio = new Audio('/sound_beep-29.mp3');
    audio.play();

    if (check) {
      // if item is in Theme.cart add quantity and must item.quantity > 0
      if (item.quantity > 0 || check.quantity < item.maxqrt) {
        item.quantity = item.quantity - 1;
        Theme.setCart(Theme.cart.map((i) => i._id === item._id ? { ...i, quantity: ++i.quantity } : i))
      }
    } else {
      // if item is not in Theme.cart add item to Theme.cart
      if (item.quantity > 0) {
        item.quantity = item.quantity - 1;
        Theme.setCart([...Theme.cart, { ...item, quantity: 1, maxqrt: item.quantity }]);
        console.log('Theme.cart');
      }
    }
  }


  useEffect(() => {
    getAllCat();
    getAllProduct();
  }, []);

  const getAllCat = async () => {
    let response = await allCategory();
    setCategory(response.data);
  }


  const getSubCat = async (e) => {
    let response = await allSubcatogery('category/' + e);
    setsubCategory(response.data);
  }

  // filter by category
  const filterCat = async (id) => {
    let response = await allProduct();
    let result = response.data.data.filter((item) => item.category._id === id);
    setCard(result);
    getSubCat(id)
  }
  // filter by subcategory
  const filterSubCat = async (id) => {
    let response = await allProduct();
    let result = response.data.data.filter((item) => item.subcategory === id);
    setCard(result);
  }



  const getAllProduct = async () => {
    let response = await allProduct();
    setCard(response.data.data);
  }



  return (
    <div className="home">

      <div className="catg">
        {category.map((cat) => (
          <p key={cat._id} value={cat._id} onClick={() => filterCat(cat._id)} >
            <img src={cat.image} alt="" />
            <span>{cat.name}</span>

          </p>
        ))}
      </div>
      <div className="catgs">
        {subCategory.length > 0 && <p>sub menu </p>}
        <div className="catg">
          {subCategory.length > 0 && subCategory.map((sub) => (
            <p key={sub._id} value={sub._id} onClick={() => filterSubCat(sub._id)}>
              <img src={sub.image ? sub.image : sub.category.image} alt="" />
              <span>{sub.name}</span>
            </p>
          ))}
        </div>
      </div>
      <section className="cards">
        {
          card.map((card, index) => (

            <div className="card" key={card._id}>
              <NavLink to={'/product/' + card._id} className="card__image-container">
                <img className="card__image" src={card.image} alt={card.name} />
              </NavLink>
              <span className="card__price">{
                // discount before price and after price Discount
                card.discount > 0 ? (
                  <div>
                    <span style={
                      { textDecoration: 'line-through', color: 'red' }
                    }>{card.price}$ </span>
                    <span className="card__price--after">{(card.price - (card.price * card.discount / 100)).toFixed(2)}$</span>
                  </div>
                ) : (
                  <span className="card__price--after">{card.price}$</span>
                )
              }</span>
              <span className="card__name">{card.name}</span>
              {/* <p className="qry">qry; {card.quantity}</p> */}
              <div className="edit">
                {/* <button onClick={() => { addCart(card) }}> <img src="/cart1.png" style={{width:'44px',height:'44px'}} />  </button> */}
                {/* <button onClick={() => Theme.setCart([...Theme.cart.slice(0, index), { ...item, quantity: (item.quantity == item.quantity ? item.quantity :item.quantity + 1 ) }, ...Theme.cart.slice(index + 1)])}>+</button> */}
                {/* make add card */}
                <button onClick={() => { addCart(card) }}> <img src="/cart1.png" style={{ width: '44px', height: '44px' }} />  </button>


              </div>
            </div>
          ))  // end map

        }
      </section>
    </div>
  );
}


export default () => {

  return (
    <main>
      <Header />
      <DarkVariantExample />
    </main>
  )
}