

import axios from "axios"
 


const tablesUrl = '/api/v1/tables';

 
export const allTables = async (id) => {
    id = id || '';
    return await axios.get(`${tablesUrl}/${id}`);
}

export const addTables = async (tables, atoken) => {
    return await axios.post(`${tablesUrl}`, tables, { headers: { token: atoken } });
}

export const deleteTables = async (id, atoken) => {
    return await axios.delete(`${tablesUrl}/${id}`, { headers: { token: atoken } });
}

export const editTables = async (id, tables, atoken) => {
    return await axios.put(`${tablesUrl}/${id}`, tables, { headers: { token: atoken } });
}
