import Header from "../../header/Header";
import { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { allTables, addTables, deleteTables, editTables } from '../../api/Table';
import Button from '@mui/material/Button';

import './cashier2.scss';
import { ThemeContext } from '../../App';
import axios from "axios";

import io from 'socket.io-client';




const Kitchen = () => {
  const Theme = useContext(ThemeContext);
  const { t, i18n } = useTranslation();

  const [tables, setTables] = useState([]);


  useEffect(() => {
    const socket = io('/');

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    socket.on('tableItemUpdated', (data) => {
      // new Audio('/bottleput.mp3').play();
      console.log(`Received echo: ${data}`);
      getAllTable();
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const getAllTable = useCallback(async () => {
    let response = await allTables('items/Pending');
    setTables(response.data);
  }, []);

  useEffect(() => {
    getAllTable();
  }, [getAllTable]);

  useEffect(() => {
    new Audio('/bottleput.mp3').play();
  }, [tables.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllTable();
    }, 111000);
    return () => clearInterval(intervalId);
  }, [getAllTable]);

  const getTableId = useCallback(async (card) => {
    await axios.put(`/api/v1/tables/items/${card._id}`,
      { status: "Ready" },
      { headers: { token: Theme.user.accessToken } });
    getAllTable();
  }, [Theme.user.accessToken, getAllTable]);

  const memoizedTables = useMemo(() => {
    return tables.map((card) => (
      <div className={`table ${card.status}`} key={card._id}>
        <Button onClick={() => getTableId(card)}>Ready</Button>
        <div className="number">T {card.table?.tableNumber}</div>
        <div className="tname">{card.table?.name}</div>
        {
          card.item && card.item.map((item) => (
            <div className="titem" key={item._id}>
              <div className="qry">qry {item.quantity}</div>
              <div className="name">{item.name}
              {/* modifier */}

              <div className="modifier">
                {item?.modifier &&
                  item.modifier.map((mod) => (
                    <div
                      className={"modifier__item " + (mod.active ? "active" : "")}
                      key={mod._id}
                    > 
                      <div className="name">
                        <span>{mod.name}</span>
                      </div>
                    </div>
                  ))
                  }
              </div>
              {/* modifier */}
              </div>
              <div className="note"> {item.note}</div>

            </div>
          ))
        }
      </div>
    ));
  }, [tables, getTableId]);

  return (
    <div className="Kitchen">
      <h1>Kitchen {tables.length}</h1>
      <div className="Kitchen__right">
        <div className="kitchen">
          {memoizedTables}
        </div>
      </div>
    </div>
  );
};

export default () => {
  return (
    <main>
      <Header />
      <Kitchen />
    </main>
  );
};

