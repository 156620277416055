import Header from '../../header/Header';
import ReactDOM from "react-dom"
import { useContext } from 'react';
import { FaUsersCog } from "react-icons/fa";

import './dashboard.scss'
import './Log.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { allUser, addUser, deleteUser, editUser } from '../../api/User';
import { ThemeContext } from '../../App';
import Menu from '../../Components/menu';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';


import axios from "axios";
import {
    ListItem,
    ListItemText,
    Button,
    Modal,
    TextField,
    makeStyles
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
        maxHeight: '80vh',
    },
}));



function List() {
    const Theme = useContext(ThemeContext);

    const [user, setUser] = useState([]);
    const [show, setShow] = useState(false);

    const classes = useStyles();


    const [log, setLog] = useState([]);
    const [showLog, setShowLog] = useState(false);



    // // get logs by user id with load more button 
    // router.get("/user/:userId/:limit", async (req, res) => {
    //     try {
    //         const userId = req.params.userId;
    //         const limit = parseInt(req.params.limit);
    //         const logs = await Log.find({ user: userId }).limit(limit);
    //         res.status(200).json(logs);
    //     } catch (err) {
    //         res.status(500).json(err);
    //     }
    // });
    useEffect(() => {
        getallUser();
        listLog();
    }, []);

    const listLog = async (id, limit) => {
        try {
            let response = await axios.get('/api/v1/log/user/' + id + '/' + limit);
            setLog(response.data);
            setShow(true);
        } catch (err) {
            console.log(err);
        }
    }

    const allLog = async (limit) => {
        try {
            let response = await axios.get('/api/v1/log/all/' + limit);
            setLog(response.data);
            setShow(true);
        } catch (err) {
            console.log(err);
        }
    }



    const getallUser = async () => {
        let response = await allUser();
        setUser(response.data);
    }


    return (
        <div className='dashboard' >
            <Menu />
            <div className='body'>
                <h1> <FaUsersCog size={28} /> Log Users</h1>
                <p> overview of your Logs Users </p>
                <ToastContainer />


                <Modal
                    open={show}
                    onClose={() => setShow(false)}
                    className={classes.modal}
                >
                    <div className={classes.paper}>

                        <table>
                            <thead>
                                <tr> <th>name</th>   <th>createdAt</th> <th>action</th> </tr>
                            </thead>
                            {
                                log && log.map((card) => (
                                    <tr key={card._id}>
                                        <td>{card.user?.name}</td>
                                        {/* <td><img src={card.user.imageUrl} alt="card" className="card__img" /></td> */}
                                        <td>{card.createdAt.slice(0, 10)}</td>
                                        <td>{card.action}</td>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </Modal>


                <section className="cards">
                    <div className="Logs">
                        <div className="Log all" onClick={() => allLog(55)}>All Log</div>
                        {
                            user.map((card) => (
                                <div key={card._id} className="Log" onClick={() => listLog(card._id, 5)}>
                                    <div className="card__img">
                                        <img src={card.imageUrl} alt="card" className="card__img" />
                                    </div>
                                    <div className="card__content">
                                        <h3 className="card__title">{card.name}</h3>
                                        <p className="role">{card.role}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </section>
            </div>
        </div>
    );
}


function User() {
    return (
        <div>
            <Header />
            <div className="form admin-Product">

                <List />
            </div>
        </div>
    );
}


export default User;